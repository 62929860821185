import { supabase } from '../supabase/supabase';
import { Tasker } from 'common/src/utils/types';


export const fetchTaskerById = async (id: number): Promise<Tasker | null> => {
  const { data, error } = await supabase
    .from('taskers')
    .select('*')
    .eq('id', id)
    .single();

  if (error) {
    console.error('Error fetching tasker:', error);
    throw error;
  }

  return data;
};
