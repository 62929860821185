import React, { useEffect, useState } from 'react';
import { Tasks } from 'common/src/utils/types';
import { fetchTasksForTasker } from '../../api/tasks';
import { useAuth } from '../../hooks/useAuth';
import TaskerCalendar from '../../components/Calendar/TaskerCalendar';

const Calendar: React.FC = () => {
  const [tasks, setTasks] = useState<Tasks[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { taskerId } = useAuth();

  useEffect(() => {
    const loadTasks = async () => {
      if (taskerId) {
        setIsLoading(true);
        try {
          const tasksData = await fetchTasksForTasker(taskerId.toString());
          setTasks(tasksData);
        } catch (error) {
          console.error('Error fetching tasks:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadTasks();
  }, [taskerId]);

  return (
    <div className="w-full h-screen bg-white">
      <h1 className="text-2xl font-bold mb-4 px-4">Tu Calendario de Tasks</h1>
      <TaskerCalendar tasks={tasks} isLoading={isLoading} currentUserId={taskerId ?? 0} />
    </div>
  );
};

export default Calendar;