import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../../supabase/supabase";
import TaskerBasicInfo from "../../components/TaskerProfileCreation/TaskerBasicInfo";
import ProfileCreationRightColumn from "../../components/TaskerProfileCreation/ProfileCreationRightColumn";
import TaskerLoadingScreen from "../../components/TaskerProfileCreation/TaskerLoadingScreen";
import { toast } from "react-toastify";

const TaskerProfileCreation: React.FC = () => {
  const [taskerInfo, setTaskerInfo] = useState({
    name: "",
    last_name: "",
    phone_number: "+56 ",
    university: "",
    career: "",
    university_year: "",
    rut: "",
    only_tasks_online: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { email, password } = location.state as { email: string; password: string };
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [files, setFiles] = useState({
    certificate_regular_alumn: null,
    photo_selfie: null,
    certificate_criminal_record: null,
  });
  const [currentStep, setCurrentStep] = useState(1);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTaskerInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (name: string, file: File) => {
    setFiles((prev) => ({ ...prev, [name]: file }));
  };

  const handleSubmit = async (newCategories: number[]) => {
    setIsLoading(true);
    try {
      toast.loading("Creando perfil...");

      // Sign up the user
      const { data: { user }, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
      });

      if (signUpError) throw signUpError;
      if (!user) throw new Error('No se devolvieron datos de usuario');

      // Create tasker profile
      const { data: tasker, error: taskerError } = await supabase
        .from('taskers')
        .insert([
          {
            auth_id: user.id,
            email: email,
            ...taskerInfo,
            is_approved: false,
            profile_completed: true,
            application_status: "pending_documents",
          },
        ])
        .select()
        .single();

      if (taskerError) throw taskerError;
      if (!tasker) throw new Error('No se creó el perfil del tasker');

      // Create tasker_categories entries
      const taskerCategoriesData = newCategories.map((categoryId) => ({
        tasker_id: tasker.id,
        category_id: categoryId,
      }));

      const { error: categoriesError } = await supabase
        .from("taskers_categories")
        .insert(taskerCategoriesData);

      if (categoriesError) throw categoriesError;

      toast.dismiss();
      toast.success("Perfil creado exitosamente");
      navigate('/tasker/upload-documents');
    } catch (error) {
      toast.dismiss();
      console.error("Error in handleSubmit:", error);
      toast.error("Error al crear el perfil. Por favor, intente nuevamente.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <TaskerLoadingScreen />;
  }

  return (
    <div className="flex flex-col md:flex-row h-screen w-full overflow-y-auto overflow-x-hidden">
      <TaskerBasicInfo
        taskerInfo={taskerInfo}
        handleInputChange={handleInputChange}
        handleSubmit={() => handleSubmit(selectedCategories)}
        isLoading={isLoading}
        handleFileChange={handleFileChange}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setSelectedCategories={setSelectedCategories}
        selectedCategories={selectedCategories}
        files={files}
        email={email}
        password={password}
      />
      <ProfileCreationRightColumn step={currentStep} />
    </div>
  );
};

export default TaskerProfileCreation;
