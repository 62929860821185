export const CategoryConfig: Record<string, {
  nombre: string;
  fields: Array<{
    name: string;
    type: 'text' | 'select' | 'date' | 'file' | 'textarea' | 'multi-select' | 'group';
    label: string;
    options?: string[];
    condition?: {
      field: string;
      value: string | string[];
    };
    placeholder?: string;
    fields?: Array<{
      name: string;
      type: 'text' | 'select' | 'date' | 'file' | 'textarea' | 'multi-select' | 'group';
      label: string;
      options?: string[];
      condition?: {
        field: string;
        value: string | string[];
      };
    }>;
  }>;
}> = {
  "Transporte / Despacho / Encomienda": {
    nombre: "Transporte / Despacho / Encomienda",
    fields: [
      { name: "vehicle_type", 
        type: "select", 
        label: "Tipo de Vehículo", 
        options: ["Auto", "Camión", "Moto"] 
      },
      { name: "brand", 
        type: "text", 
        label: "Marca del Vehículo" 
      },
      { name: "model", 
        type: "text", 
        label: "Modelo del Vehículo" 
      },
      { name: "license_type", 
        type: "select", 
        label: "Tipo de Licencia", 
        options: ["Clase B", "Clase C", "Clase A", "Otro"] 
      },
      { name: "license_expiry", 
        type: "date", 
        label: "Fecha de Expiración de Licencia" 
      },
      { name: "license_photo", 
        type: "file", 
        label: "Foto de la Licencia" 
      },
      { name: "vehicle_registration", 
        type: "file", 
        label: "Certificado de Inscripción del Vehículo" 
      },
      { name: "insurance_policy", 
        type: "file", 
        label: "Póliza de Seguro del Vehículo" 
      },
      { name: "technical_review", 
        type: "date", 
        label: "Fecha de Vencimiento de la Revisión Técnica" 
      }
    ]
  },
  "Clases Particulares": {
  nombre: "Clases Particulares",
  fields: [
    { 
      name: "education_level", 
      type: "select", 
      label: "Nivel de Clase", 
      options: ["Básica", "Media", "Educación Superior"] 
    },
    { 
      name: "subjects", 
      type: "multi-select", 
      label: "Materias", 
      options: [
        "Ciencias", "Matemáticas", "Historia", "Arte", 
        "Música", "Lenguaje", "Inglés", "Física", 
        "Química", "Biología", "Contabilidad", 
        "Finanzas", "Estadística", "Economía", 
        "Programación", "Álgebra", "Cálculo", "Excel"
      ] 
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Descripción de la Experiencia" 
    },
    { 
      name: "certificates", 
      type: "file", 
        label: "Certificados o Documentos de Experiencia (Opcional)" 
      }
    ]
  },
  "Maestro Chasquilla": {
  nombre: "Maestro Chasquilla",
  fields: [
    { 
      name: "has_tools", 
      type: "select", 
      label: "Tienes Herramientas", 
      options: ["Sí", "No"] 
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Descripción de tu Experiencia" 
      }
    ]
  },
  "Fletes / Mudanzas": {
  nombre: "Fletes / Mudanzas",
  fields: [
    { 
      name: "vehicle_type", 
      type: "select", 
      label: "Tipo de Vehículo", 
      options: ["Camioneta", "Furgón", "Camión"] 
    },
    { 
      name: "vehicle_brand", 
      type: "text", 
      label: "Marca del Vehículo" 
    },
    { 
      name: "vehicle_model", 
      type: "text", 
      label: "Modelo del Vehículo" 
    },
    { 
      name: "vehicle_plate", 
      type: "text", 
      label: "Patente del Vehículo" 
    },
    { 
      name: "packing_skill", 
      type: "select", 
      label: "Habilidad para Embalar", 
      options: ["Sí", "No"] 
    },
    { 
      name: "additional_equipment", 
      type: "multi-select", 
      label: "Equipos Adicionales", 
      options: [
        "Cinta Adhesiva", 
        "Material para Embalaje (burbujas, papel kraft, cajas)", 
        "Guantes"
      ] 
    },
    { 
      name: "service_description", 
      type: "textarea", 
      label: "Descripción del Servicio" 
    }
    ]
  },
  "Garzón / Bartender / Evento / DJ": {
    nombre: "Garzón / Bartender / Evento / DJ",
    fields: [
      { 
        name: "service_type", 
        type: "select", 
        label: "Tipo de Servicio", 
        options: ["Garzón en Eventos", "Coordinador de Eventos", "DJ para Eventos", "Bartender"] 
      },
      { 
        name: "garzon_attire", 
        type: "multi-select", 
        label: "Checklist de Ropa (Garzón en Eventos)", 
        options: ["Camisa Blanca", "Camisa Negra", "Zapatos Blancos", "Zapatos Negros", "Jeans Negros", "Jeans Azules"], 
        condition: { field: "service_type", value: "Garzón en Eventos" }
      },
      { 
        name: "garzon_experience", 
        type: "textarea", 
        label: "Describe tu Experiencia (Garzón en Eventos)", 
        condition: { field: "service_type", value: "Garzón en Eventos" }
      },
      { 
        name: "event_coordinator_experience", 
        type: "multi-select", 
        label: "Checklist de Experiencia (Coordinador de Eventos)", 
        options: ["Cumpleaños", "Fiestas", "Ferias", "Eventos Deportivos", "Lanzamiento de Productos"], 
        condition: { field: "service_type", value: "Coordinador de Eventos" }
      },
      { 
        name: "coordinator_experience_description", 
        type: "textarea", 
        label: "Describe tu Experiencia (Coordinador de Eventos)", 
        condition: { field: "service_type", value: "Coordinador de Eventos" }
      },
      { 
        name: "dj_equipment", 
        type: "multi-select", 
        label: "Checklist de Artefactos (DJ para Eventos)", 
        options: ["Equipos de Sonido (Parlantes)", "Luces", "Lasers", "Micrófonos"], 
        condition: { field: "service_type", value: "DJ para Eventos" }
      },
      { 
        name: "dj_experience", 
        type: "textarea", 
        label: "Describe tu Experiencia (DJ para Eventos)", 
        condition: { field: "service_type", value: "DJ para Eventos" }
      },
      { 
        name: "bartender_equipment", 
        type: "multi-select", 
        label: "Checklist de Materiales (Bartender)", 
        options: [
          "Coctelera (Shaker)", "Medidor de Licor (Jigger)", "Cuchara de Bar", 
          "Muddler", "Colador (Strainer)", "Exprimidor de Cítricos", 
          "Cuchillo de Bar y Tabla de Cortar", "Abridor de Botellas y Sacacorchos", 
          "Cubeta de Hielo y Pinzas de Hielo", "Guantes", "Paños de Limpieza"
        ], 
        condition: { field: "service_type", value: "Bartender" }
      },
      { 
        name: "cocktails_known", 
        type: "textarea", 
        label: "Lista de Tragos que Sabes Hacer (Bartender)", 
        condition: { field: "service_type", value: "Bartender" }
      },
      { 
        name: "bartender_experience", 
        type: "textarea", 
        label: "Describe tu Experiencia (Bartender)", 
        condition: { field: "service_type", value: "Bartender" }
      },
      { 
        name: "bartender_certificate", 
        type: "file", 
        label: "Certificado de Curso (Opcional) (Bartender)", 
        condition: { field: "service_type", value: "Bartender" }
      }
    ]
  },
  "Fotografía / Edición / Reels": {
  nombre: "Fotografía / Edición / Reels",
  fields: [
    { 
      name: "service_type", 
      type: "multi-select", 
      label: "Tipo de Servicio", 
      options: [
        "Fotografía de Eventos o Videos (bodas, cumpleaños, eventos corporativos, etc.)", 
        "Edición de Fotos (retoque, corrección de color, ajuste de imagen)", 
        "Creación de Reels (videos cortos para redes sociales, edición de clips dinámicos)"
      ] 
    },
    { 
      name: "photography_equipment", 
      type: "multi-select", 
      label: "Checklist de Equipos (Fotografía o Videos)", 
      options: ["Cámara", "Lente", "Trípode", "Flashes", "Micrófono"], 
      condition: { field: "service_type", value: "Fotografía de Eventos o Videos (bodas, cumpleaños, eventos corporativos, etc.)" }
    },
    { 
      name: "editing_software", 
      type: "multi-select", 
      label: "Software Utilizado (Edición)", 
      options: [
        "Adobe Photoshop", 
        "Adobe Lightroom", 
        "Final Cut Pro", 
        "Adobe Premiere Pro", 
        "Otros (Especificar)"
      ], 
      condition: { field: "service_type", value: "Edición de Fotos (retoque, corrección de color, ajuste de imagen)" }
    },
    { 
      name: "other_software_specify", 
      type: "text", 
      label: "Especificar Otro Software", 
      condition: { field: "editing_software", value: "Otros (Especificar)" }
    }
  ]
},
"Tecnología / Computación": {
  nombre: "Tecnología / Computación",
  fields: [
    { 
      name: "service_type", 
      type: "select", 
      label: "Tipo de Servicio", 
      options: [
        "Reparación de Computadoras", 
        "Configuración de Redes", 
        "Asesoría en Tecnología"
      ] 
    },
    { 
      name: "computer_repair_checklist", 
      type: "multi-select", 
      label: "Reparación de Computadoras (Checklist)", 
      options: [
        "Diagnóstico y reparación de hardware", 
        "Solución de problemas de software", 
        "Reemplazo de componentes y actualización de sistemas"
      ], 
      condition: { field: "service_type", value: "Reparación de Computadoras" }
    },
    { 
      name: "network_setup_checklist", 
      type: "multi-select", 
      label: "Configuración de Redes (Checklist)", 
      options: [
        "Instalación y configuración de redes domésticas y de oficina", 
        "Optimización de redes y resolución de problemas de conectividad"
      ], 
      condition: { field: "service_type", value: "Configuración de Redes" }
    },
    { 
      name: "tech_advisory_checklist", 
      type: "multi-select", 
      label: "Asesoría en Tecnología (Checklist)", 
      options: [
        "Consultoría para selección y compra de equipos", 
        "Asesoramiento en soluciones tecnológicas y software", 
        "Capacitación en el uso de herramientas y aplicaciones tecnológicas"
      ], 
      condition: { field: "service_type", value: "Asesoría en Tecnología" }
    },
    { 
      name: "diagnostic_tools", 
      type: "multi-select", 
      label: "Herramientas de Diagnóstico (Opcional)", 
      options: [
        "Software de diagnóstico de hardware (HDD Regenerator, Memtest86, etc.)", 
        "Herramientas de análisis de rendimiento y mantenimiento"
      ]
    },
    { 
      name: "network_tools", 
      type: "multi-select", 
      label: "Software de Configuración de Redes (Opcional)", 
      options: [
        "Programas para la configuración de routers y switches", 
        "Herramientas de gestión de redes y análisis de tráfico (Wireshark, SolarWinds, etc.)"
      ]
    },
    { 
      name: "skills_description", 
      type: "textarea", 
      label: "Descripción de Habilidades"
    },
    { 
      name: "certifications", 
      type: "multi-select", 
      label: "Certificaciones en TI (Opcional)", 
      options: [
        "CompTIA A+", 
        "Cisco CCNA", 
        "Microsoft Certified Professional", 
        "Otros (Especificar)"
      ]
    },
    { 
      name: "other_certifications", 
      type: "text", 
      label: "Especificar Otras Certificaciones", 
      condition: { field: "certifications", value: "Otros (Especificar)" }
    }
  ]
},
"Arquitectura / Ilustración": {
  nombre: "Arquitectura / Ilustración",
  fields: [
    { 
      name: "service_type", 
      type: "select", 
      label: "Tipo de Servicio", 
      options: ["Diseño Arquitectónico", "Ilustraciones Personalizadas"] 
    },
    { 
      name: "architectural_design_checklist", 
      type: "multi-select", 
      label: "Diseño Arquitectónico (Checklist)", 
      options: [
        "Diseño de Planos", 
        "Modelado 3D y Renderizados", 
        "Diseño de Interiores", 
        "Rehabilitación y Restauración", 
        "Urbanismo y Planificación Urbana", 
        "Supervisión de Obras", 
        "Consultoría en Sostenibilidad", 
        "Modelado BIM (Building Information Modeling)"
      ], 
      condition: { field: "service_type", value: "Diseño Arquitectónico" }
    },
    { 
      name: "illustration_checklist", 
      type: "multi-select", 
      label: "Ilustraciones Personalizadas (Checklist)", 
      options: [
        "Ilustración Editorial", 
        "Ilustración Publicitaria", 
        "Concept Art", 
        "Ilustración Técnica", 
        "Ilustración Científica", 
        "Ilustración Digital", 
        "Arte Personalizado"
      ], 
      condition: { field: "service_type", value: "Ilustraciones Personalizadas" }
    },
    { 
      name: "architectural_software", 
      type: "multi-select", 
      label: "Software Utilizado (Diseño Arquitectónico)", 
      options: [
        "AutoCAD", 
        "Revit", 
        "SketchUp", 
        "3ds Max", 
        "ArchiCAD", 
        "Rhino", 
        "Lumion", 
        "Adobe Illustrator", 
        "Photoshop", 
        "BIM Tools (Navisworks, BIM 360)"
      ], 
      condition: { field: "service_type", value: "Diseño Arquitectónico" }
    },
    { 
      name: "illustration_software", 
      type: "multi-select", 
      label: "Software Utilizado (Ilustraciones Personalizadas)", 
      options: [
        "Adobe Illustrator", 
        "Adobe Photoshop", 
        "CorelDRAW", 
        "Procreate", 
        "Clip Studio Paint", 
        "Affinity Designer", 
        "Autodesk SketchBook", 
        "Blender (para ilustración 3D)", 
        "Inkscape (software de código abierto)"
      ], 
      condition: { field: "service_type", value: "Ilustraciones Personalizadas" }
    },
    { 
      name: "portfolio", 
      type: "file", 
      label: "Portafolio (Muestras de Trabajo Anterior)"
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Describe tu Experiencia"
    }
  ]
},
"Diseño": {
  nombre: "Diseño",
  fields: [
    { 
      name: "service_type", 
      type: "select", 
      label: "Tipo de Servicio", 
      options: [
        "Diseño Gráfico", 
        "Diseño Web", 
        "Diseño de Interiores", 
        "Diseño de Productos", 
        "Diseño de Experiencia de Usuario (UX)"
      ] 
    },
    { 
      name: "graphic_design_checklist", 
      type: "multi-select", 
      label: "Diseño Gráfico (Checklist)", 
      options: [
        "Identidad Corporativa (Creación de Logotipos, Diseño de Branding y Manuales de Marca)",
        "Diseño Publicitario (Banners Publicitarios, Materiales Impresos)",
        "Diseño Editorial (Maquetación de Revistas, Libros y Catálogos)",
        "Diseño de Packaging (Diseño de Empaques y Etiquetas)",
        "Infografías y Gráficos (Creación de Gráficos Informativos y Diagramas)",
        "Diseño Digital (Gráficos para Redes Sociales, Diseño de Presentaciones)"
      ], 
      condition: { field: "service_type", value: "Diseño Gráfico" }
    },
    { 
      name: "web_design_checklist", 
      type: "multi-select", 
      label: "Diseño Web (Checklist)", 
      options: [
        "Diseño de Páginas Web (Diseño de Interfaces, Diseño Responsivo)",
        "Desarrollo Front-End (HTML/CSS/JavaScript, Integración con CMS)",
        "E-commerce (Diseño y Configuración de Tiendas Online, Optimización de UX en Compras)",
        "Optimización SEO (Diseño para Optimización en Motores de Búsqueda, Mejora de Velocidad de Carga)",
        "Landing Pages (Creación de Páginas de Aterrizaje, Integración con Herramientas de Marketing)"
      ], 
      condition: { field: "service_type", value: "Diseño Web" }
    },
    { 
      name: "interior_design_checklist", 
      type: "multi-select", 
      label: "Diseño de Interiores (Checklist)", 
      options: [
        "Planificación de Espacios (Distribución Habitacional y Comercial, Diseño de Mobiliario a Medida)",
        "Selección de Materiales y Acabados (Asesoría en Materiales, Combinación de Texturas y Colores)",
        "Decoración de Interiores (Selección de Mobiliario, Iluminación y Accesorios, Estilismo de Espacios)",
        "Renderizados 3D (Visualización en 3D, Animaciones y Walkthroughs Virtuales)"
      ], 
      condition: { field: "service_type", value: "Diseño de Interiores" }
    },
    { 
      name: "product_design_checklist", 
      type: "multi-select", 
      label: "Diseño de Productos (Checklist)", 
      options: [
        "Diseño Industrial (Desarrollo de Prototipos, Optimización para Producción en Masa)",
        "Diseño de Muebles (Creación de Mobiliario Funcional y Estético, Muebles Personalizados)",
        "Diseño de Utensilios y Accesorios (Productos de Uso Diario, Innovación en Productos de Consumo)"
      ], 
      condition: { field: "service_type", value: "Diseño de Productos" }
    },
    { 
      name: "ux_design_checklist", 
      type: "multi-select", 
      label: "Diseño de Experiencia de Usuario (UX) (Checklist)", 
      options: [
        "Investigación y Análisis de Usuario (Estudios de Usabilidad y Comportamiento)",
        "Wireframing y Prototipado (Wireframes y Prototipos Interactivos)",
        "Testing y Optimización (Pruebas de Usabilidad, Ajustes Basados en Feedback)",
        "Arquitectura de Información (Diseño de Estructura de Contenidos, Flujos de Navegación)"
      ], 
      condition: { field: "service_type", value: "Diseño de Experiencia de Usuario (UX)" }
    },
    { 
      name: "graphic_design_software", 
      type: "multi-select", 
      label: "Software Utilizado (Diseño Gráfico)", 
      options: [
        "Adobe Illustrator", 
        "Adobe Photoshop", 
        "CorelDRAW", 
        "InDesign", 
        "Affinity Designer", 
        "Sketch", 
        "Canva"
      ], 
      condition: { field: "service_type", value: "Diseño Gráfico" }
    },
    { 
      name: "web_design_software", 
      type: "multi-select", 
      label: "Software Utilizado (Diseño Web)", 
      options: [
        "Adobe XD", 
        "Figma", 
        "Sketch", 
        "WordPress", 
        "Elementor", 
        "Webflow", 
        "Bootstrap", 
        "Visual Studio Code", 
        "Google Analytics"
      ], 
      condition: { field: "service_type", value: "Diseño Web" }
    },
    { 
      name: "interior_design_software", 
      type: "multi-select", 
      label: "Software Utilizado (Diseño de Interiores)", 
      options: [
        "AutoCAD", 
        "SketchUp", 
        "Revit", 
        "3ds Max", 
        "Lumion", 
        "V-Ray", 
        "Photoshop"
      ], 
      condition: { field: "service_type", value: "Diseño de Interiores" }
    },
    { 
      name: "product_design_software", 
      type: "multi-select", 
      label: "Software Utilizado (Diseño de Productos)", 
      options: [
        "SolidWorks", 
        "Rhinoceros (Rhino)", 
        "Blender", 
        "Fusion 360", 
        "KeyShot", 
        "Procreate"
      ], 
      condition: { field: "service_type", value: "Diseño de Productos" }
    },
    { 
      name: "ux_design_software", 
      type: "multi-select", 
      label: "Software Utilizado (Diseño de Experiencia de Usuario)", 
      options: [
        "Figma", 
        "Sketch", 
        "Adobe XD", 
        "InVision", 
        "Axure RP", 
        "Balsamiq", 
        "Hotjar"
      ], 
      condition: { field: "service_type", value: "Diseño de Experiencia de Usuario (UX)" }
    },
    { 
      name: "portfolio", 
      type: "file", 
      label: "Portafolio (Muestra tu Portafolio)"
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Describe tu Experiencia"
    }
  ]
},
"Chef": {
  nombre: "Chef",
  fields: [
    { 
      name: "service_type", 
      type: "select", 
      label: "Tipo de Servicio", 
      options: ["Catering", "Chef a Domicilio", "Consultoría Gastronómica", "Clases de Cocina", "Preparación de Comidas Especiales"] 
    },
    { 
      name: "catering_checklist", 
      type: "multi-select", 
      label: "Catering (Checklist)", 
      options: [
        "Eventos Corporativos", 
        "Bodas y Celebraciones", 
        "Fiestas Privadas", 
        "Cenas Formales", 
        "Brunch y Desayunos"
      ], 
      condition: { field: "service_type", value: "Catering" }
    },
    { 
      name: "private_chef_checklist", 
      type: "multi-select", 
      label: "Chef a Domicilio (Checklist)", 
      options: [
        "Cenas Personalizadas", 
        "Experiencias Gastronómicas", 
        "Clases de Cocina Privadas", 
        "Servicios de Comida para la Semana"
      ], 
      condition: { field: "service_type", value: "Chef a Domicilio" }
    },
    { 
      name: "consulting_checklist", 
      type: "multi-select", 
      label: "Consultoría Gastronómica (Checklist)", 
      options: [
        "Desarrollo de Menús", 
        "Asesoría en Cocina y Operaciones", 
        "Optimización de Costos y Proveedores"
      ], 
      condition: { field: "service_type", value: "Consultoría Gastronómica" }
    },
    { 
      name: "cooking_classes_checklist", 
      type: "multi-select", 
      label: "Clases de Cocina (Checklist)", 
      options: [
        "Cocina Internacional", 
        "Cocina Saludable", 
        "Repostería y Pastelería", 
        "Cocina Vegana y Vegetariana", 
        "Clases para Niños"
      ], 
      condition: { field: "service_type", value: "Clases de Cocina" }
    },
    { 
      name: "special_meals_checklist", 
      type: "multi-select", 
      label: "Preparación de Comidas Especiales (Checklist)", 
      options: [
        "Dietas Especiales (Keto, Paleo, Sin Gluten)", 
        "Comida para Diabéticos", 
        "Comida Vegana y Vegetariana", 
        "Comida para Personas con Alergias Alimentarias"
      ], 
      condition: { field: "service_type", value: "Preparación de Comidas Especiales" }
    },
    { 
      name: "culinary_specialties", 
      type: "multi-select", 
      label: "Especialidades Culinarias", 
      options: [
        "Cocina Italiana", 
        "Cocina Francesa", 
        "Cocina Asiática (Sushi)", 
        "Cocina Mediterránea", 
        "Cocina Mexicana", 
        "Cocina de Fusión", 
        "Repostería y Pastelería", 
        "Cocina Saludable y Orgánica"
      ]
    },
    { 
      name: "equipment_and_utensils", 
      type: "select", 
      label: "¿Proporcionas tus Propios Utensilios de Cocina?", 
      options: ["Sí", "No"] 
    },
    { 
      name: "own_equipment_list", 
      type: "textarea", 
      label: "Lista de Equipos que Puedes Llevar", 
      condition: { field: "equipment_and_utensils", value: "Sí" }
    },
    { 
      name: "client_provided_equipment", 
      type: "select", 
      label: "¿Necesitas que el Cliente Proporcione Algún Equipamiento?", 
      options: ["Sí", "No"] 
    },
    { 
      name: "required_equipment_list", 
      type: "textarea", 
      label: "Lista de Equipos Necesarios", 
      condition: { field: "client_provided_equipment", value: "Sí" }
    },
    { 
      name: "dietary_requirements_experience", 
      type: "select", 
      label: "¿Tienes Experiencia en Preparación de Comidas para Dietas Especiales?", 
      options: ["Sí", "No"] 
    },
    { 
      name: "dietary_experience_details", 
      type: "textarea", 
      label: "Detallar Experiencia en Dietas Especiales", 
      condition: { field: "dietary_requirements_experience", value: "Sí" }
    },
    { 
      name: "menu_adaptations", 
      type: "select", 
      label: "¿Puedes Adaptar Menús para Alergias Alimentarias?", 
      options: ["Sí", "No"] 
    },
    { 
      name: "allergy_adaptations_details", 
      type: "textarea", 
      label: "Detallar Tipos de Adaptaciones para Alergias", 
      condition: { field: "menu_adaptations", value: "Sí" }
    },
    { 
      name: "portfolio", 
      type: "file", 
      label: "Portafolio (Opcional)"
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Describe tu Experiencia"
    },
    { 
      name: "chef_attire", 
      type: "text", 
      label: "Para cualquier Task, tienes que ir con tu vestimenta de Chef."
    }
  ]
},
"Limpieza y Organización": {
  nombre: "Limpieza y Organización",
  fields: [
    { 
      name: "service_type", 
      type: "multi-select", 
      label: "Tipo de Servicio", 
      options: [
        "Limpieza Residencial", 
        "Limpieza Profunda", 
        "Limpieza de Fin de Obra", 
        "Limpieza de Ventanas", 
        "Limpieza Comercial", 
        "Organización de Hogares", 
        "Organización de Oficinas", 
        "Organización Post-Mudanza"
      ] 
    },
    { 
      name: "service_frequency", 
      type: "select", 
      label: "Frecuencia del Servicio", 
      options: ["Único", "Semanal", "Quincenal", "Mensual", "Ocasional"] 
    },
    { 
      name: "provide_cleaning_products", 
      type: "select", 
      label: "¿Proporcionas tus Propios Productos de Limpieza?", 
      options: ["Sí", "No"] 
    },
    { 
      name: "cleaning_products_list", 
      type: "textarea", 
      label: "Lista de Productos Utilizados", 
      placeholder: "Ej. Ecológicos, Antibacterianos, etc.", 
      condition: { field: "provide_cleaning_products", value: "Sí" }
    },
    { 
      name: "provide_cleaning_tools", 
      type: "select", 
      label: "¿Proporcionas tus Propias Herramientas de Limpieza?", 
      options: ["Sí", "No"] 
    },
    { 
      name: "cleaning_tools_detail", 
      type: "textarea", 
      label: "Detalle de Herramientas", 
      placeholder: "Ej. Aspiradoras, Trapeadores, etc.", 
      condition: { field: "provide_cleaning_tools", value: "Sí" }
    },
    { 
      name: "client_provided_materials", 
      type: "select", 
      label: "¿Necesitas que el Cliente Proporcione Algún Material?", 
      options: ["Sí", "No"] 
    },
    { 
      name: "required_materials_list", 
      type: "textarea", 
      label: "Lista de Materiales Necesarios", 
      condition: { field: "client_provided_materials", value: "Sí" }
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Describe tu Experiencia"
    }
  ]
},
"Personal Trainer / Kinesiología": {
  nombre: "Personal Trainer / Kinesiología",
  fields: [
    { 
      name: "service_type", 
      type: "select", 
      label: "Tipo de Servicio", 
      options: ["Entrenamiento Personal", "Kinesiología", "Asesoría en Nutrición Deportiva", "Entrenamiento Deportivo", "Entrenamiento para la Salud"] 
    },
    { 
      name: "personal_training_checklist", 
      type: "multi-select", 
      label: "Entrenamiento Personal (Checklist)", 
      options: [
        "Entrenamiento en Gimnasio (Rutinas Personalizadas, Entrenamientos de Fuerza, Entrenamientos Cardiovasculares)", 
        "Entrenamiento en Casa (Rutinas Adaptadas a Espacios Reducidos, Equipamiento Básico)", 
        "Entrenamiento al Aire Libre (Sesiones en Parques, Ejercicios en la Naturaleza)"
      ], 
      condition: { field: "service_type", value: "Entrenamiento Personal" }
    },
    { 
      name: "kinesiology_checklist", 
      type: "multi-select", 
      label: "Kinesiología (Checklist)", 
      options: [
        "Evaluación y Diagnóstico", 
        "Rehabilitación Post-Quirúrgica", 
        "Terapias Especializadas", 
        "Prevención de Lesiones"
      ], 
      condition: { field: "service_type", value: "Kinesiología" }
    },
    { 
      name: "nutrition_advice_checklist", 
      type: "multi-select", 
      label: "Asesoría en Nutrición Deportiva (Checklist)", 
      options: [
        "Planes Alimenticios", 
        "Suplementación"
      ], 
      condition: { field: "service_type", value: "Asesoría en Nutrición Deportiva" }
    },
    { 
      name: "sports_training_checklist", 
      type: "multi-select", 
      label: "Entrenamiento Deportivo (Checklist)", 
      options: [
        "Deportes Específicos (Fútbol, Ciclismo, Atletismo, etc.)", 
        "Entrenamiento para Competencias"
      ], 
      condition: { field: "service_type", value: "Entrenamiento Deportivo" }
    },
    { 
      name: "health_training_checklist", 
      type: "multi-select", 
      label: "Entrenamiento para la Salud (Checklist)", 
      options: [
        "Ejercicios para Mejora de la Postura", 
        "Rehabilitación de Dolor Crónico"
      ], 
      condition: { field: "service_type", value: "Entrenamiento para la Salud" }
    },
    { 
      name: "equipment_used", 
      type: "multi-select", 
      label: "Equipamiento Utilizado", 
      options: [
        "Pesas y Equipos de Gimnasio", 
        "Bandas de Resistencia", 
        "Equipos de Ejercicio Cardiovascular (Cinta, Bicicleta, etc.)", 
        "Equipos de Electroterapia", 
        "Material de Rehabilitación (Pelotas, Bandas, etc.)", 
        "Herramientas para Terapia Manual"
      ]
    },
    { 
      name: "certifications", 
      type: "file", 
      label: "Certificaciones (Opcional)"
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Describe tu Experiencia"
    }
  ]
},
"Promotor y Modelaje": {
  nombre: "Promotor y Modelaje",
  fields: [
    { 
      name: "service_type", 
      type: "select", 
      label: "Tipo de Servicio", 
      options: ["Promotor", "Modelaje"] 
    },
    { 
      name: "promoter_events_fairs", 
      type: "multi-select", 
      label: "Promotor en Eventos y Ferias (Checklist)", 
      options: [
        "Promoción de Productos", 
        "Gestión de Stands y Actividades"
      ], 
      condition: { field: "service_type", value: "Promotor" }
    },
    { 
      name: "promoter_campaigns", 
      type: "multi-select", 
      label: "Campañas Publicitarias (Checklist)", 
      options: [
        "Promoción de Marcas en Medios Digitales y Tradicionales", 
        "Estrategias de Marketing en Redes Sociales"
      ], 
      condition: { field: "service_type", value: "Promotor" }
    },
    { 
      name: "promoter_point_of_sale", 
      type: "multi-select", 
      label: "Actividades en Punto de Venta (Checklist)", 
      options: [
        "Demostraciones de Productos", 
        "Activaciones de Marca en Tiendas"
      ], 
      condition: { field: "service_type", value: "Promotor" }
    },
    { 
      name: "promoter_event_marketing", 
      type: "multi-select", 
      label: "Marketing en Eventos (Checklist)", 
      options: [
        "Organización y Coordinación de Eventos", 
        "Gestión de Relaciones con Medios y Influencers"
      ], 
      condition: { field: "service_type", value: "Promotor" }
    },
    { 
      name: "modeling_fashion_runway", 
      type: "multi-select", 
      label: "Modelaje de Moda y Pasarela (Checklist)", 
      options: [
        "Desfiles de Moda", 
        "Sesiones de Fotografía de Moda"
      ], 
      condition: { field: "service_type", value: "Modelaje" }
    },
    { 
      name: "modeling_advertising_media", 
      type: "multi-select", 
      label: "Publicidad y Medios (Checklist)", 
      options: [
        "Campañas Publicitarias en Revistas y Medios Digitales", 
        "Anuncios en Televisión y Radio"
      ], 
      condition: { field: "service_type", value: "Modelaje" }
    },
    { 
      name: "modeling_commercial", 
      type: "multi-select", 
      label: "Modelaje Comercial (Checklist)", 
      options: [
        "Fotografía para Catálogos y E-commerce", 
        "Promoción de Productos y Servicios en Medios"
      ], 
      condition: { field: "service_type", value: "Modelaje" }
    },
    { 
      name: "modeling_editorial", 
      type: "multi-select", 
      label: "Modelaje de Editorial (Checklist)", 
      options: [
        "Trabajo en Editoriales de Moda", 
        "Colaboraciones con Fotógrafos y Diseñadores"
      ], 
      condition: { field: "service_type", value: "Modelaje" }
    },
    { 
      name: "modeling_sizes", 
      type: "multi-select", 
      label: "Tallas (Modelaje)", 
      options: [
        "S (Small)", 
        "M (Medium)", 
        "L (Large)", 
        "XL (Extra Large)", 
        "XXL (Double Extra Large)", 
        "Otra Talla (Especificar)"
      ], 
      condition: { field: "service_type", value: "Modelaje" }
    },
    { 
      name: "shoe_size", 
      type: "text", 
      label: "Calzado" 
    },
    { 
      name: "promoter_shirt_size", 
      type: "select", 
      label: "Talla Polera (Promotor)", 
      condition: { field: "service_type", value: "Modelaje" }
    },
    { 
      name: "promoter_shirt_size", 
      type: "select", 
      label: "Talla Polera (Promotor)", 
      options: ["S (Small)", "M (Medium)", "L (Large)", "XL (Extra Large)", "XXL (Double Extra Large)", "Otra Talla (Especificar)"], 
      condition: { field: "service_type", value: "Promotor" }
    },
    { 
      name: "promoter_other_size", 
      type: "text", 
      label: "Especificar Otra Talla", 
      condition: { field: "promoter_shirt_size", value: "Otra Talla (Especificar)" }
    },
    { 
      name: "portfolio", 
      type: "file", 
      label: "Portafolio (Opcional): Muestras de Trabajo Anterior"
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Describe tu Experiencia"
    }
  ]
},
"Mecánica": {
  nombre: "Mecánica",
  fields: [
    { 
      name: "service_type", 
      type: "select", 
      label: "Tipo de Servicio", 
      options: ["Mecánica Automotriz", "Mecánica de Moto", "Mecánica de Camiones y Vehículos Comerciales"] 
    },
    { 
      name: "automotive_mechanics_checklist", 
      type: "multi-select", 
      label: "Mecánica Automotriz (Checklist)", 
      options: [
        "Mantenimiento General (Cambio de Aceite, Revisión y Reemplazo de Filtros, Inspección de Frenos)", 
        "Reparación de Componentes (Reparación de Motor, Reparación de Transmisión, Reemplazo de Batería)", 
        "Diagnóstico de Fallos (Diagnóstico de Problemas Eléctricos, Diagnóstico de Sistemas de Inyección, Diagnóstico de Problemas de Suspensión)", 
        "Servicios Especializados (Reparación de Sistemas de Escape, Servicio de Alineación y Balanceo)"
      ], 
      condition: { field: "service_type", value: "Mecánica Automotriz" }
    },
    { 
      name: "motorcycle_mechanics_checklist", 
      type: "multi-select", 
      label: "Mecánica de Moto (Checklist)", 
      options: [
        "Mantenimiento General (Cambio de Aceite y Filtros, Revisión de Frenos, Ajuste de Cadena y Suspensión)", 
        "Reparación de Componentes (Reparación de Motor, Reparación de Transmisión, Reemplazo de Batería y Sistemas Eléctricos)", 
        "Diagnóstico de Fallos (Diagnóstico de Problemas de Motor, Diagnóstico de Problemas de Suspensión, Diagnóstico de Problemas Eléctricos)"
      ], 
      condition: { field: "service_type", value: "Mecánica de Moto" }
    },
    { 
      name: "commercial_vehicle_mechanics_checklist", 
      type: "multi-select", 
      label: "Mecánica de Camiones y Vehículos Comerciales (Checklist)", 
      options: [
        "Mantenimiento General (Cambio de Aceite y Filtros, Inspección de Sistemas de Frenos y Suspensión, Revisión de Sistemas de Enfriamiento)", 
        "Reparación de Componentes (Reparación de Motor y Transmisión, Reemplazo de Sistemas de Escape, Reemplazo de Batería)", 
        "Diagnóstico de Fallos (Diagnóstico de Problemas de Motor, Diagnóstico de Problemas de Transmisión, Diagnóstico de Sistemas Eléctricos)"
      ], 
      condition: { field: "service_type", value: "Mecánica de Camiones y Vehículos Comerciales" }
    },
    { 
      name: "equipment_used", 
      type: "multi-select", 
      label: "Equipamiento Utilizado", 
      options: [
        "Herramientas de Taller (Elevadores de Autos, Herramientas de Diagnóstico Electrónico, Equipos de Prueba de Frenos, Compresores de Aire, Equipos de Alineación y Balanceo)", 
        "Herramientas Específicas (Escáner OBD-II, Gato Hidráulico, Juegos de Llaves y Herramientas Manuales, Equipos de Prueba de Sistemas de Inyección)"
      ]
    },
    { 
      name: "certifications", 
      type: "file", 
      label: "Certificaciones (Opcional)"
    },
    { 
      name: "portfolio", 
      type: "file", 
      label: "Portafolio"
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Describe tu Experiencia"
    }
  ]
},
"Jardinería": {
  nombre: "Jardinería",
  fields: [
    { 
      name: "services_offered", 
      type: "multi-select", 
      label: "Servicios Ofrecidos", 
      options: [
        "Mantenimiento General del Jardín", 
        "Poda de Árboles y Arbustos", 
        "Siembra de Plantas y Flores", 
        "Diseño de Jardines", 
        "Instalación y Reparación de Sistemas de Riego", 
        "Control de Plagas", 
        "Cortado de Césped", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "service_spaces", 
      type: "multi-select", 
      label: "Tipo de Espacios en los que Trabajas", 
      options: [
        "Jardines Residenciales", 
        "Áreas Verdes Públicas", 
        "Parques y Plazas", 
        "Terrazas o Jardines Verticales", 
        "Empresas y Oficinas", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "certifications_documents", 
      type: "file", 
      label: "Certificaciones y Documentos (Opcional)", 
      placeholder: "Adjunta cualquier certificación o documento relevante que avale tu formación o experiencia en jardinería."
    },
    { 
      name: "tools_equipment", 
      type: "multi-select", 
      label: "Herramientas y Equipos que tienes y podrías llevar al Task", 
      options: [
        "Podadoras", 
        "Tijeras de Poda", 
        "Cortacésped", 
        "Herramientas de Jardinería Manuales", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "portfolio", 
      type: "file", 
      label: "Portafolio de Proyectos Anteriores (Opcional)", 
      placeholder: "Adjunta fotos de jardines o áreas verdes que hayas trabajado. Esto ayudará a que los clientes vean tu estilo y nivel de trabajo."
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Experiencia y Formación", 
      placeholder: "Describe tu experiencia en jardinería, incluyendo años de experiencia, proyectos relevantes y cualquier formación o certificación.",
    },
    { 
      name: "additional_comments", 
      type: "textarea", 
      label: "Comentarios Adicionales (Opcional)", 
      placeholder: "Agrega cualquier información adicional relevante, como políticas de cancelación, requisitos especiales, o experiencias únicas que ofreces.",
    }
  ]
},
"Webs / Shopify / WordPress": {
  nombre: "Webs / Shopify / WordPress",
  fields: [
    { 
      name: "platforms_experience", 
      type: "multi-select", 
      label: "Plataformas con las que Trabajas", 
      options: [
        "WordPress", 
        "Shopify", 
        "WooCommerce", 
        "Wix", 
        "Squarespace", 
        "Magento", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "services_offered", 
      type: "multi-select", 
      label: "Servicios Ofrecidos", 
      options: [
        "Diseño Web Personalizado", 
        "Desarrollo de Tiendas Online", 
        "Optimización SEO", 
        "Mantenimiento de Sitios Web", 
        "Integración de Pagos", 
        "Configuración de Hosting", 
        "Migración de Sitios Web", 
        "Personalización de Temas/Plantillas", 
        "Solución de Errores y Soporte Técnico", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "portfolio", 
      type: "file", 
      label: "Portafolio de Proyectos (Opcional)", 
      placeholder: "Adjunta ejemplos de proyectos anteriores o enlaces a sitios web que has desarrollado o diseñado. Puedes incluir imágenes, enlaces o capturas de pantalla."
    },
    { 
      name: "certifications_documents", 
      type: "file", 
      label: "Certificaciones y Documentos (Opcional)", 
      placeholder: "Adjunta cualquier certificación o documento que respalde tu formación y habilidades en diseño web, desarrollo, SEO, o e-commerce."
    },
    { 
      name: "tools_technologies", 
      type: "multi-select", 
      label: "Herramientas y Tecnologías que Utilizas", 
      options: [
        "WordPress", 
        "Shopify", 
        "WooCommerce", 
        "Elementor", 
        "Divi", 
        "HTML/CSS/JavaScript", 
        "PHP/MySQL", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Experiencia y Formación", 
      placeholder: "Describe tu experiencia y formación en el diseño y desarrollo de sitios web, incluyendo años de experiencia y proyectos importantes.",
    },
    { 
      name: "additional_comments", 
      type: "textarea", 
      label: "Comentarios Adicionales (Opcional)", 
      placeholder: "Agrega cualquier información adicional relevante, como políticas de cancelación o experiencias únicas que ofreces.",
    }
  ]
},
"Gasfitería y Electricidad Sencilla": {
  nombre: "Gasfitería y Electricidad Sencilla",
  fields: [
    { 
      name: "services_offered", 
      type: "multi-select", 
      label: "Servicios Ofrecidos", 
      options: [
        "Gasfitería", 
        "Electricidad Sencilla"
      ] 
    },
    { 
      name: "gasfiteria_services", 
      type: "multi-select", 
      label: "Servicios de Gasfitería", 
      options: [
            "Instalación y Reparación de Tuberías", 
            "Reparación de Fugas de Agua", 
            "Instalación de Grifos y Llaves", 
            "Instalación y Reparación de Inodoros", 
            "Instalación y Reparación de Calefones", 
            "Desatoro de Drenajes", 
            "Otros (especificar)"
      ]
    },
    { 
      name: "electricidad_services", 
      type: "multi-select", 
      label: "Servicios de Electricidad Sencilla", 
      options: [
        "Instalación de Interruptores y Enchufes", 
        "Reparación de Cortocircuitos", 
        "Instalación de Iluminación", 
        "Reemplazo de Fusibles", 
        "Reparación de Toma Corrientes", 
        "Otros (especificar)"
      ]
    },
    { 
      name: "certifications_documents", 
      type: "file", 
      label: "Certificaciones y Documentos (Opcional)", 
      placeholder: "Adjunta cualquier certificación o documento que avale tu formación y habilidades en gasfitería o electricidad sencilla."
    },
    { 
      name: "tools_equipment", 
      type: "multi-select", 
      label: "Herramientas que tienes para poder llevar a los Tasks", 
      options: [
        "Llave Stillson", 
        "Taladro Eléctrico", 
        "Detector de Voltaje", 
        "Desatascador Manual", 
        "Alicates", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Experiencia y Formación", 
      placeholder: "Describe tu experiencia en gasfitería y electricidad sencilla, incluyendo años de experiencia y tipo de trabajos realizados.",
    },
    { 
      name: "additional_comments", 
      type: "textarea", 
      label: "Comentarios Adicionales (Opcional)", 
      placeholder: "Agrega cualquier información adicional relevante, como políticas de cancelación o experiencias únicas que ofreces.",
    }
  ]
},
"Instalación de Aparatos": {
  nombre: "Instalación de Aparatos",
  fields: [
    { 
      name: "appliance_types", 
      type: "multi-select", 
      label: "Tipos de Aparatos que Instalas", 
      options: [
        "Televisores", 
        "Aires Acondicionados", 
        "Lavadoras y Secadoras", 
        "Refrigeradores", 
        "Hornos/Microondas", 
        "Equipos de Sonido", 
        "Sistemas de Seguridad", 
        "Impresoras", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "services_offered", 
      type: "multi-select", 
      label: "Servicios Ofrecidos", 
      options: [
        "Mantenimiento Preventivo", 
        "Reparaciones", 
        "Diagnóstico de Fallas", 
        "Reemplazo de Piezas", 
        "Asesoría Técnica", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Experiencia y Formación", 
      placeholder: "Describe tu experiencia en la instalación de aparatos, incluyendo años de experiencia y formación técnica relevante.",
    },
    { 
      name: "certifications_documents", 
      type: "file", 
      label: "Certificaciones y Documentos (Opcional)", 
      placeholder: "Adjunta cualquier certificación o documento relevante que avale tu formación en instalación, mantenimiento o reparación de aparatos."
    },
    { 
      name: "tools_equipment", 
      type: "multi-select", 
      label: "Equipos y Herramientas que Utilizas", 
      options: [
        "Taladro", 
        "Multímetro", 
        "Detector de Cables", 
        "Herramientas para Montaje", 
        "Equipos de Soldadura", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "additional_comments", 
      type: "textarea", 
      label: "Comentarios Adicionales (Opcional)", 
      placeholder: "Agrega cualquier información adicional relevante, como políticas de cancelación o experiencias únicas que ofreces.",
    }
  ]
},
"Salud y Cuidado de Adultos Mayores": {
  nombre: "Salud y Cuidado de Adultos Mayores",
  fields: [
    { 
      name: "services_offered", 
      type: "multi-select", 
      label: "Servicios Ofrecidos", 
      options: [
        "Cuidado Domiciliario", 
        "Asistencia en Tareas Diarias (alimentación, higiene, etc.)", 
        "Acompañamiento", 
        "Rehabilitación Física", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "experience_skills", 
      type: "textarea", 
      label: "Experiencia y Habilidades", 
      placeholder: "Describe tu experiencia y habilidades en el cuidado de adultos mayores, incluyendo años de experiencia y habilidades especializadas.",
    },
    { 
      name: "certifications", 
      type: "file", 
      label: "Certificaciones Extra (Opcional)", 
      placeholder: "Adjunta tus certificados o documentos que respalden tu formación en el área de salud o cuidado de adultos mayores."
    },
    { 
      name: "health_conditions_handled", 
      type: "multi-select", 
      label: "Condiciones de Salud Específicas que Manejas", 
      options: [
        "Alzheimer", 
        "Demencia", 
        "Enfermedad de Parkinson", 
        "Diabetes", 
        "Hipertensión", 
        "Movilidad Reducida", 
        "Problemas Respiratorios", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "references_testimonials", 
      type: "file", 
      label: "Referencias y Testimonios (Opcional)", 
      placeholder: "Adjunta referencias de empleadores anteriores o testimonios de familias con las que has trabajado."
    },
    { 
      name: "personal_description", 
      type: "textarea", 
      label: "Descripción Personal", 
      placeholder: "Añade una breve descripción sobre ti, tu enfoque en el cuidado de adultos mayores, y tu motivación para trabajar en esta área.",
    },
    { 
      name: "additional_comments", 
      type: "textarea", 
      label: "Comentarios Adicionales (Opcional)", 
      placeholder: "Agrega cualquier información adicional relevante, como políticas de cancelación o experiencias únicas que ofreces.",
    }
  ]
},
"Mascotas": {
  nombre: "Mascotas",
  fields: [
    { 
      name: "pet_types", 
      type: "multi-select", 
      label: "Tipo de Mascota", 
      options: [
        "Perro", 
        "Gato", 
        "Ave", 
        "Roedor", 
        "Reptil", 
        "Pez", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "services_offered", 
      type: "multi-select", 
      label: "Servicios Ofrecidos", 
      options: [
        "Paseo de Perros", 
        "Cuidado de Mascotas en Casa", 
        "Entrenamiento", 
        "Cuidado de Salud (baños, cortes de uñas, etc.)", 
        "Hospedaje", 
        "Transporte de Mascotas", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "experience_description", 
      type: "textarea", 
      label: "Experiencia", 
      placeholder: "Describe tu experiencia en el cuidado de mascotas, incluyendo años de experiencia y habilidades específicas.",
    },
    { 
      name: "certifications_training", 
      type: "file", 
      label: "Certificaciones y Formación (Opcional)", 
      placeholder: "Adjunta cualquier certificado o formación relevante relacionada con el cuidado de mascotas, entrenamiento, o primeros auxilios veterinarios."
    },
    { 
      name: "portfolio", 
      type: "file", 
      label: "Portafolio de Servicios (Opcional)", 
      placeholder: "Adjunta fotos de tus trabajos anteriores, como paseos, entrenamientos, o cuidado de mascotas."
    },
    { 
      name: "additional_comments", 
      type: "textarea", 
      label: "Comentarios Adicionales (Opcional)", 
      placeholder: "Agrega cualquier información adicional relevante, como políticas de cancelación o requisitos especiales para mascotas.",
    }
  ]
},
"Influencers": {
  nombre: "Influencers",
  fields: [
    { 
      name: "platforms_presence", 
      type: "multi-select", 
      label: "Plataformas en las que tienes presencia", 
      options: [
        "Instagram", 
        "TikTok", 
        "YouTube", 
        "Twitter/X", 
        "Facebook", 
        "Twitch", 
        "Otras (especificar)"
      ] 
    },
    { 
      name: "followers_count", 
      type: "group", 
      label: "Número de Seguidores", 
      fields: [
        { name: "instagram_followers", type: "text", label: "Instagram" },
        { name: "tiktok_followers", type: "text", label: "TikTok" },
        { name: "youtube_followers", type: "text", label: "YouTube" },
        { name: "twitter_followers", type: "text", label: "Twitter/X" },
        { name: "facebook_followers", type: "text", label: "Facebook" },
        { name: "twitch_followers", type: "text", label: "Twitch" },
        { name: "other_platforms_followers", type: "text", label: "Otras (especificar)" }
      ]
    },
    { 
      name: "content_type", 
      type: "multi-select", 
      label: "Tipo de Contenido que Creas", 
      options: [
        "Moda", 
        "Belleza", 
        "Viajes", 
        "Estilo de Vida", 
        "Tecnología", 
        "Educación", 
        "Entretenimiento", 
        "Fitness", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "previous_collaborations", 
      type: "file", 
      label: "Colaboraciones Anteriores (Opcional)", 
      placeholder: "Adjunta un portafolio o lista de colaboraciones anteriores. Puedes incluir imágenes, videos o enlaces a publicaciones."
    },
    { 
      name: "relevant_statistics", 
      type: "group", 
      label: "Estadísticas Relevantes", 
      fields: [
        { name: "average_reach", type: "text", label: "Alcance promedio de tus publicaciones" },
        { name: "engagement", type: "text", label: "Engagement (opcional)" },
        { name: "monthly_growth", type: "text", label: "Crecimiento mensual de seguidores (opcional)" },
        { name: "average_views", type: "text", label: "Vistas promedio en videos/reels/historias (opcional)" }
      ]
    },
    { 
      name: "collaboration_category", 
      type: "multi-select", 
      label: "Categoría de Colaboración", 
      options: [
        "Publicaciones Patrocinadas", 
        "Embajador de Marca", 
        "Publicaciones en Historias", 
        "Sorteos y Concursos", 
        "Eventos Presenciales", 
        "Canje", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "personal_brand_description", 
      type: "textarea", 
      label: "Descripción de tu Marca Personal", 
      placeholder: "Describe tu estilo, enfoque y lo que te hace único como influencer. Incluye tu tono de comunicación, los temas que sueles tratar y tu visión como creador de contenido.",
    }
  ]
},
"Maquillaje y Peinado": {
  nombre: "Maquillaje y Peinado",
  fields: [
    { 
      name: "service_type", 
      type: "multi-select", 
      label: "Tipo de Servicio", 
      options: [
        "Maquillaje para Eventos", 
        "Peinado para Eventos", 
        "Maquillaje y Peinado para Novias", 
        "Otros (especificar)"
      ] 
    },
    { 
      name: "makeup_products", 
      type: "group", 
      label: "Productos de Maquillaje Utilizados", 
      fields: [
        { 
          name: "foundation_type", 
          type: "multi-select", 
          label: "Base de Maquillaje", 
          options: ["Líquida", "en Polvo", "en Crema"] 
        },
        { 
          name: "concealer_type", 
          type: "multi-select", 
          label: "Corrector", 
          options: ["Líquido", "en Crema", "Barra"] 
        },
        { 
          name: "eyeshadows_type", 
          type: "multi-select", 
          label: "Sombras de Ojos", 
          options: ["Paletas Neutras", "Paletas de Colores", "Sombras en Crema", "Pigmentos Sueltos"] 
        },
        { 
          name: "blush_type", 
          type: "multi-select", 
          label: "Rubor", 
          options: ["Polvo Compacto", "Líquido", "Crema"] 
        },
        { 
          name: "highlighter_type", 
          type: "multi-select", 
          label: "Iluminador", 
          options: ["en Polvo", "en Crema", "Líquido"] 
        },
        { 
          name: "lipstick_type", 
          type: "multi-select", 
          label: "Labiales", 
          options: ["Mate", "Gloss", "Cremoso", "Tinte de Labios"] 
        },
        { 
          name: "mascara_type", 
          type: "multi-select", 
          label: "Máscara de Pestañas", 
          options: ["Volumen", "Alargadora", "Resistente al Agua"] 
        },
        { 
          name: "custom_makeup_product", 
          type: "text", 
          label: "Añadir Producto (Personalizado)" 
        }
      ]
    },
    { 
      name: "tools_used", 
      type: "group", 
      label: "Herramientas Utilizadas", 
      fields: [
        { 
          name: "makeup_brushes", 
          type: "multi-select", 
          label: "Brochas de Maquillaje", 
          options: ["Brocha para Base", "Brocha para Polvo", "Brocha para Sombras", "Brocha para Rubor", "Brocha para Contorno"] 
        },
        { 
          name: "sponges_applicators", 
          type: "multi-select", 
          label: "Esponjas y Aplicadores", 
          options: ["Beauty Blender", "Aplicadores de Sombras", "Esponjas de Maquillaje Planas"] 
        },
        { 
          name: "eye_tools", 
          type: "multi-select", 
          label: "Herramientas para Ojos", 
          options: ["Rizador de Pestañas", "Pincel para Delineador", "Cepillo para Cejas", "Pinzas para Cejas"] 
        },
        { 
          name: "lip_tools", 
          type: "multi-select", 
          label: "Herramientas para Labios", 
          options: ["Pincel de Labios", "Perfilador de Labios"] 
        },
        { 
          name: "additional_equipment", 
          type: "multi-select", 
          label: "Equipos Adicionales", 
          options: ["Aro de Luz", "Espejo con Luz", "Mini Ventilador", "Paleta de Mezcla"] 
        },
        { 
          name: "custom_tool", 
          type: "text", 
          label: "Añadir Herramienta (Personalizada)" 
        }
      ]
    },
    { 
      name: "certifications", 
      type: "file", 
      label: "Certificaciones y Formación (Opcional)", 
      placeholder: "Adjunta un archivo PDF o imagen de tus certificados de formación profesional o especializaciones relevantes."
    },
    { 
      name: "portfolio", 
      type: "file", 
      label: "Portafolio de Trabajos Anteriores", 
      placeholder: "Adjunta tu portafolio de trabajos previos. Aceptamos imágenes, enlaces o PDF."
    },
    { 
      name: "description", 
      type: "textarea", 
      label: "Descripción", 
      placeholder: "Describe tu experiencia profesional en maquillaje y peinado, incluyendo eventos destacados y técnicas dominadas.",
    },
    { 
      name: "additional_comments", 
      type: "textarea", 
      label: "Comentarios Adicionales (Opcional)", 
      placeholder: "Agrega cualquier información adicional relevante, como políticas de cancelación o experiencias únicas que ofreces.",
    }
  ]
},
  // Add more categories here...
};