import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const phrases = [
  "Prepárate para ser un Tasker",
  "Estamos configurando tu perfil",
  "Casi listo, un momento más"
];

const TaskerLoadingScreen: React.FC = () => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 5000); // Change phrase every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 bg-purple-600 flex flex-col items-center justify-center z-50">
      <motion.h1 
        className="text-4xl font-bold text-white mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        key={currentPhraseIndex} // Ensure the animation runs on phrase change
      >
        {phrases[currentPhraseIndex]}
      </motion.h1>
      <motion.div 
        className="flex space-x-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            className="w-4 h-4 bg-white rounded-full"
            animate={{
              y: [0, -20, 0],
              transition: {
                duration: 0.8,
                repeat: Infinity,
                delay: i * 0.2,
              },
            }}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default TaskerLoadingScreen;
