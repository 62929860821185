import React from 'react';
import { FaCheck, FaCamera } from 'react-icons/fa';

interface StepProgressProps {
  currentStep: number;
  totalSteps: number;
}

const StepProgress: React.FC<StepProgressProps> = ({ currentStep, totalSteps }) => {
  const steps = ['Iniciar', 'Ejecutar', 'Documentar'];

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center px-2">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className="flex flex-col items-center">
              <div
                className={`w-12 h-12 rounded-full flex items-center justify-center ${
                  index < currentStep
                    ? 'bg-purple-500 text-white'
                    : index === currentStep
                    ? 'bg-purple-200 text-purple-700'
                    : 'bg-gray-200 text-gray-500'
                }`}
              >
                {index < currentStep ? (
                  <FaCheck className="w-6 h-6" />
                ) : index === currentStep && currentStep === totalSteps ? (
                  <FaCamera className="w-6 h-6" />
                ) : (
                  index + 1
                )}
              </div>
              <span className="mt-2 text-sm font-medium text-gray-600">{step}</span>
            </div>
            {index < totalSteps - 1 && (
              <div
                className={`flex-1 h-1 ${
                  index < currentStep - 1 ? 'bg-purple-500' : 'bg-gray-200'
                }`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default StepProgress;