import React from 'react';
import { Tasks } from '../../../utils/types';
import { fetchTasksForTasker } from '../../../api/tasks';
import { useAuth } from '../../../hooks/useAuth';
import { Card, Button, Spinner } from '@nextui-org/react';
import { FaPlay } from 'react-icons/fa';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const AcceptedTasksList: React.FC = () => {
	const { taskerId } = useAuth();
	const navigate = useNavigate();

	const { data: tasks, isLoading, error } = useQuery({
		queryKey: ['acceptedTasks', taskerId],
		queryFn: () => fetchTasksForTasker(taskerId?.toString() || ''),
		enabled: !!taskerId,
		select: (data) => data.filter(task => 
			task.tasker_id === taskerId && 
			['accepted', 'in_progress', 'progress', 'assigned'].includes(task.status)
		),
	});

	if (isLoading) return (
		<div className="flex justify-center items-center h-screen">
			<Spinner size="lg" color="secondary" />
		</div>
	);

	if (error) return <div className="text-center text-red-500">Error al cargar las tareas</div>;

	const formatDate = (date: string) => {
		return new Date(date).toLocaleDateString('es-ES', {
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			});
	};

	const formatPrice = (price: number) => {
		return new Intl.NumberFormat('es-ES', {
				style: 'currency',
				currency: 'CLP',
				minimumFractionDigits: 0
			}).format(price);
	};

	const handleStartTask = (taskId: number) => {
		navigate(`/task-execution/${taskId}`);
	};

	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mx-2 sm:mx-4 md:mx-6 lg:mx-8 my-4 sm:my-6 md:my-8">
			{tasks && tasks.length > 0 ? (
				tasks.map((task) => (
					<Card key={task.id} className="bg-gradient-to-br from-purple-400 to-purple-500 text-white p-4 sm:p-6">
						<div className="flex flex-col h-full">
							<h3 className="text-lg sm:text-xl font-bold mb-1">{task.category}</h3>
							<p className="text-xs sm:text-sm mb-2">Ofrecida el {formatDate(task.created_at)}</p>
							<p className="text-sm flex-grow mb-4 line-clamp-2">{task.description}</p>
							<div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
								<span className="text-lg sm:text-xl font-bold mb-2 sm:mb-0">{formatPrice(task.price)}</span>
								<Button 
									size="sm"
									color="secondary" 
									className="bg-white text-purple-500 w-full sm:w-auto"
									onClick={() => handleStartTask(task.id)}
								>
									<FaPlay fill="currentColor" size={14} />
									<span className="ml-1">Empezar task!</span>
								</Button>
							</div>
						</div>
					</Card>
				))
			) : (
				<div className="col-span-full text-center text-gray-500 p-4">No hay tareas aceptadas</div>
			)}
		</div>
	);
};
