import React, { useEffect, useState } from 'react';
import { supabase } from 'src/supabase/supabase';
import { Card, CardBody, CardHeader, Spinner } from '@nextui-org/react';
import { useAuth } from 'src/hooks/useAuth'; // Make sure to import useAuth

interface Tasker {
  id: number;
  auth_id: string;
  name: string;
  last_name: string;
  email: string;
  phone_number: string;
  university: string;
  carreer: string;
  university_year: string;
  only_tasks_online: boolean;
  own_movilization: boolean;
  certificate_regular_alumn_url: string | null;
  photo_selfie_url: string | null;
  brand: string | null;
  model: string | null;
  patent: string | null;
  certificate_criminal_record_url: string | null;
  created_at: string;
  updated_at: string;
}

interface Category {
  id: number;
  name: string;
}

interface SecondaryCategory extends Category {
  primary_categories_id: number;
}

interface TertiaryCategory extends Category {
  secondary_categories_id: number;
}

interface TaskerCategory {
  tasker_id: number;
  primary_categories_id: number | null;
  secondary_categories_id: number | null;
  tertiary_categories_id: number | null;
}

const TaskerInformation: React.FC = () => {
  const [tasker, setTasker] = useState<Tasker | null>(null);
  const [categories, setCategories] = useState<{
    primary: Category[];
    secondary: SecondaryCategory[];
    tertiary: TertiaryCategory[];
  }>({
    primary: [],
    secondary: [],
    tertiary: [],
  });
  const [loading, setLoading] = useState(true);
  const { taskerId } = useAuth(); // Use the taskerId from useAuth

  useEffect(() => {
    if (taskerId) {
      fetchTaskerInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskerId]);

  const fetchTaskerInfo = async () => {
    try {
      // Fetch tasker information using taskerId
      const { data: taskerData, error: taskerError } = await supabase
        .from('taskers')
        .select('*')
        .eq('id', taskerId)
        .single();

      if (taskerError) throw taskerError;
      setTasker(taskerData);

      // Fetch tasker categories
      const { data: taskerCategories, error: categoriesError } = await supabase
        .from('taskers_categories')
        .select('*')
        .eq('tasker_id', taskerData.id);

      if (categoriesError) throw categoriesError;

      // Fetch category names
      const categoryIds = taskerCategories.reduce(
        (
          acc: { primary: number[]; secondary: number[]; tertiary: number[] },
          cat: TaskerCategory,
        ) => {
          if (cat.primary_categories_id)
            acc.primary.push(cat.primary_categories_id);
          if (cat.secondary_categories_id)
            acc.secondary.push(cat.secondary_categories_id);
          if (cat.tertiary_categories_id)
            acc.tertiary.push(cat.tertiary_categories_id);
          return acc;
        },
        { primary: [], secondary: [], tertiary: [] },
      );

      const fetchCategoryNames = async (table: string, ids: number[]) => {
        const { data, error } = await supabase
          .from(table)
          .select('*') // Change this line to select all fields
          .in('id', ids);
        if (error) throw error;
        return data;
      };

      const [primaryCategories, secondaryCategories, tertiaryCategories] =
        await Promise.all([
          fetchCategoryNames('primary_categories', categoryIds.primary),
          fetchCategoryNames('secondary_categories', categoryIds.secondary),
          fetchCategoryNames('tertiary_categories', categoryIds.tertiary),
        ]);

      setCategories({
        primary: primaryCategories,
        secondary: secondaryCategories,
        tertiary: tertiaryCategories,
      });

      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasker information:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (!tasker) {
    return <p>No tasker information found.</p>;
  }

  return (
    <Card>
      <CardHeader>
        <h1 style={{ fontSize: '24px' }}>Tasker Information</h1>
      </CardHeader>
      <CardBody>
        <h2 style={{ fontSize: '20px' }}>Basic Information</h2>
        <p>ID: {tasker.id}</p>
        <p>Name: {tasker.name || 'NULL'}</p>
        <p>Last Name: {tasker.last_name || 'NULL'}</p>
        <p>Email: {tasker.email || 'NULL'}</p>
        <p>Phone Number: {tasker.phone_number || 'NULL'}</p>
        <p>University: {tasker.university || 'NULL'}</p>
        <p>Career: {tasker.carreer || 'NULL'}</p>
        <p>University Year: {tasker.university_year || 'NULL'}</p>

        <h2 style={{ fontSize: '20px', marginTop: '4px' }}>Auth Information</h2>
        <p>Auth ID: {tasker.auth_id || 'NULL'}</p>

        <h2 style={{ fontSize: '20px', marginTop: '4px' }}>
          Additional Information
        </h2>
        <p>Only Tasks Online: {tasker.only_tasks_online ? 'Yes' : 'No'}</p>
        <p>Own Mobilization: {tasker.own_movilization ? 'Yes' : 'No'}</p>
        <p>Brand: {tasker.brand || 'NULL'}</p>
        <p>Model: {tasker.model || 'NULL'}</p>
        <p>Patent: {tasker.patent || 'NULL'}</p>
        <p>
          Certificate Regular Alumn URL:{' '}
          {tasker.certificate_regular_alumn_url || 'NULL'}
        </p>
        <p>Photo Selfie URL: {tasker.photo_selfie_url || 'NULL'}</p>
        <p>
          Certificate Criminal Record URL:{' '}
          {tasker.certificate_criminal_record_url || 'NULL'}
        </p>

        <h2 style={{ fontSize: '20px', marginTop: '4px' }}>Categories</h2>
        {categories.primary.map((primaryCat) => (
          <div key={primaryCat.id}>
            <h3 style={{ fontSize: '16px', marginLeft: '10px' }}>
              {primaryCat.name}
            </h3>
            {categories.secondary
              .filter(
                (secondaryCat) =>
                  secondaryCat.primary_categories_id === primaryCat.id,
              )
              .map((secondaryCat) => (
                <div key={secondaryCat.id} style={{ marginLeft: '20px' }}>
                  <h4 style={{ fontSize: '14px' }}>{secondaryCat.name}</h4>
                  {categories.tertiary
                    .filter(
                      (tertiaryCat) =>
                        tertiaryCat.secondary_categories_id === secondaryCat.id,
                    )
                    .map((tertiaryCat) => (
                      <p
                        key={tertiaryCat.id}
                        style={{ marginLeft: '30px', fontSize: '12px' }}
                      >
                        {tertiaryCat.name}
                      </p>
                    ))}
                </div>
              ))}
          </div>
        ))}

        <h2 style={{ fontSize: '20px', marginTop: '4px' }}>Timestamps</h2>
        <p>Created At: {new Date(tasker.created_at).toLocaleString()}</p>
        <p>Updated At: {new Date(tasker.updated_at).toLocaleString()}</p>
      </CardBody>
    </Card>
  );
};

export default TaskerInformation;
