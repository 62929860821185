import React, { useMemo, useState } from 'react';
import { Calendar, momentLocalizer, View, NavigateAction } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Tasks } from 'common/src/utils/types';
import { Spinner } from '@nextui-org/react';
import { messages, dayNames, monthNames } from './calendarConfig';
import useScreenSize from '../../hooks/useScreenSize';

moment.locale('es');

const localizer = momentLocalizer(moment);

interface TaskerCalendarProps {
  tasks: Tasks[];
  isLoading: boolean;
  currentUserId: number;
}

const TaskerCalendar: React.FC<TaskerCalendarProps> = ({ tasks, isLoading, currentUserId }) => {
  const [selectedTask, setSelectedTask] = useState<Tasks | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useScreenSize();

  const getEventStyle = () => {
    return {
      style: {
        backgroundColor: '#7c3aed', // Purple color
        borderRadius: '4px',
        opacity: 0.8,
        color: '#fff',
        border: 'none',
        display: 'block'
      }
    };
  };

  const events = tasks.map(task => ({
    id: task.id,
    title: task.category,
    start: new Date(task.date),
    end: new Date(task.date),
    resource: task
  }));

  const handleSelectEvent = (event: any) => {
    const task = tasks.find(t => t.id === event.id);
    if (task) {
      window.location.href = `/task-execution/${task.id}`;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[500px]">
        <Spinner size="lg" color="secondary" />
      </div>
    );
  }

  return (
    <div className="h-[600px] bg-white rounded-lg shadow-sm">
      <style>
        {`
          .rbc-calendar {
            font-family: Arial, sans-serif;
          }
          .rbc-header {
            padding: 10px;
            background-color: #f8f8f8;
            border: none !important;
          }
          .rbc-time-view, .rbc-month-view {
            border: none !important;
          }
          .rbc-time-content {
            border-top: 1px solid #e0e0e0 !important;
          }
          .rbc-time-header-content {
            border-left: none !important;
          }
          .rbc-today {
            background-color: #f0f0f0;
          }
          .rbc-event {
            background-color: #7c3aed;
            border: none;
            border-radius: 4px;
          }
          .rbc-day-slot .rbc-event {
            border: none;
          }
          .rbc-time-slot {
            border-top: 1px solid #f0f0f0 !important;
          }
          .rbc-time-gutter .rbc-timeslot-group {
            border-bottom: none !important;
          }
          .rbc-timeslot-group {
            border-bottom: none !important;
          }
          .rbc-time-content > * + * > * {
            border-left: none !important;
          }
          .rbc-time-header.rbc-overflowing {
            border-right: none;
          }
          .rbc-time-view .rbc-allday-cell {
            display: none;
          }
          .rbc-time-view .rbc-row {
            min-height: auto;
          }
          .rbc-time-gutter {
            font-size: 12px;
            color: #666;
          }
          .rbc-label {
            padding: 5px 5px 0 0;
          }
          @media (max-width: 640px) {
            .rbc-toolbar {
              flex-direction: column;
              align-items: stretch;
            }
            .rbc-toolbar-label {
              margin: 10px 0;
            }
            .rbc-btn-group {
              justify-content: space-between;
            }
            .rbc-btn-group button {
              padding: 5px;
            }
          }
        `}
      </style>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%' }}
        views={isMobile ? { day: true, agenda: true } : { month: true, week: true, day: true }}
        messages={messages}
        formats={{
          monthHeaderFormat: (date: Date) => `${date.getDate()} - ${new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()} de ${monthNames[date.getMonth()]} ${date.getFullYear()}`,
          weekdayFormat: (date: Date) => dayNames[date.getDay()].slice(0, 3),
          dayFormat: (date: Date) => date.getDate().toString(),
          dayRangeHeaderFormat: ({ start, end }) =>
            `${start.getDate()} - ${end.getDate()} de ${monthNames[start.getMonth()]} ${start.getFullYear()}`,
          timeGutterFormat: (date: Date, culture: string | undefined, localizer: any) => 
            localizer?.format(date, 'HH:mm', culture),
        }}
        components={{
          toolbar: (toolbarProps) => <CustomToolbar {...toolbarProps} />,
        }}
        eventPropGetter={getEventStyle}
        onSelectEvent={handleSelectEvent}
        defaultView={isMobile ? "day" : "week"}
      />
    </div>
  );
};

const CustomToolbar = ({ onNavigate, label, view, onView }: { 
  onNavigate: (action: NavigateAction) => void, 
  label: string, 
  view: string, 
  onView: (view: View) => void,
}) => {
  const isMobile = useScreenSize();

  const goToBack = () => {
    onNavigate('PREV');
  };

  const goToNext = () => {
    onNavigate('NEXT');
  };

  const viewOptions = isMobile
    ? [
        { key: 'day', label: 'Día' },
        { key: 'agenda', label: 'Agenda' },
      ]
    : [
        { key: 'month', label: 'Mes' },
        { key: 'week', label: 'Semana' },
        { key: 'day', label: 'Día' },
      ];

  return (
    <div className={`flex flex-col items-center p-4 border-b border-gray-200 bg-white rounded-t-lg ${isMobile ? 'space-y-2' : ''}`}>
      <div className={`flex w-full justify-between items-center ${isMobile ? 'mb-2' : ''}`}>
        <div className="flex items-center space-x-4">
          <div className="flex space-x-2">
            <button onClick={goToBack} className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors">
              &lt;
            </button>
            <button onClick={goToNext} className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors">
              &gt;
            </button>
          </div>
          <span className="text-lg font-semibold text-gray-700">{label}</span>
        </div>
        {!isMobile && (
          <div className="flex bg-gray-100 p-1 rounded-full">
            {viewOptions.map((viewOption) => (
              <button
                key={viewOption.key}
                onClick={() => onView(viewOption.key as View)}
                className={`px-4 py-2 text-sm rounded-full transition-colors ${
                  view === viewOption.key ? 'bg-purple-500 text-white' : 'text-gray-600 hover:bg-gray-200'
                }`}
              >
                {viewOption.label}
              </button>
            ))}
          </div>
        )}
      </div>
      {isMobile && (
        <div className="flex w-full justify-center space-x-2 bg-gray-100 p-1 rounded-full">
          {viewOptions.map((viewOption) => (
            <button
              key={viewOption.key}
              onClick={() => onView(viewOption.key as View)}
              className={`px-4 py-2 text-sm rounded-full transition-colors ${
                view === viewOption.key ? 'bg-purple-500 text-white' : 'text-gray-600 hover:bg-gray-200'
              }`}
            >
              {viewOption.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TaskerCalendar;