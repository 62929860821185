import React from 'react';
import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { Link } from 'react-router-dom';

interface DashboardCardProps {
  title: string;
  count: number;
  description: string;
  link: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, count, description, link }) => {
  console.log(`Rendering DashboardCard: ${title}, Count: ${count}`);
  return (
    <Link to={link} className="w-full">
      <Card className="w-full shadow-md rounded-2xl overflow-hidden bg-gradient-to-br from-purple-400 to-purple-500 hover:from-purple-500 hover:to-purple-600 transition-all duration-300 cursor-pointer">
        <CardHeader className="pb-0 pt-4 px-4 flex-col items-start">
          <h4 className="font-bold text-lg text-white opacity-80">{title}</h4>
        </CardHeader>
        <CardBody className="p-4 flex flex-col items-start">
          <p className="text-6xl font-bold text-white mb-2">{count}</p>
          <p className="text-xl font-semibold text-white mb-2">tasks</p>
          <p className="text-sm text-white opacity-80">{description}</p>
        </CardBody>
      </Card>
    </Link>
  );
};

export default DashboardCard;