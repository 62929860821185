import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { supabase } from '../supabase/supabase';
import { User, Session } from '@supabase/supabase-js';

interface AuthContextType {
  session: Session | null;
  user: User | null;
  taskerId: number | null;
  isApproved: boolean;
  loading: boolean;
  isAuthenticated: boolean;
  isProfileComplete: boolean;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUserStatus: () => Promise<{ isProfileComplete: boolean; isApproved: boolean }>;
  checkProfileComplete: (userId: string) => Promise<boolean>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [taskerId, setTaskerId] = useState<number | null>(null);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTaskerInfo = async (userId: string) => {
    const { data, error } = await supabase
      .from('taskers')
      .select('id, is_approved, profile_completed')
      .eq('auth_id', userId)
      .single();
    if (error) {
      console.error('Error fetching tasker info:', error);
    } else if (data) {
      setTaskerId(data.id);
      setIsApproved(data.is_approved);
      setIsProfileComplete(data.profile_completed);
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
      setUser(session?.user ?? null);
      setIsAuthenticated(!!session);
      if (session?.user) {
        await fetchTaskerInfo(session.user.id);
      }
      setLoading(false);
      setIsLoading(false);

      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (_event, session) => {
          setSession(session);
          setUser(session?.user ?? null);
          setIsAuthenticated(!!session);
          if (session?.user) {
            await fetchTaskerInfo(session.user.id);
          }
          setLoading(false);
          setIsLoading(false);
        },
      );

      return () => {
        authListener.subscription.unsubscribe();
      };
    };

    fetchSession();
  }, []);

  const login = async (email: string, password: string) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) throw error;

    setSession(data.session);
    setUser(data.user);
    if (data.user) {
      await fetchTaskerInfo(data.user.id);
    }
    setLoading(false);
    await updateUserStatus();
  };

  const updateUserStatus = async () => {
    try {
      const { data: tasker, error } = await supabase
        .from('taskers')
        .select('profile_completed, is_approved')
        .eq('auth_id', user?.id)
        .single();

      if (error) throw error;

      setIsProfileComplete(tasker.profile_completed);
      setIsApproved(tasker.is_approved);

      return {
        isProfileComplete: tasker.profile_completed,
        isApproved: tasker.is_approved
      };
    } catch (error) {
      console.error('Error updating user status:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setSession(null);
      setUser(null);
      setTaskerId(null);
      setIsApproved(false);
      setIsAuthenticated(false);
      setIsProfileComplete(false);
      setLoading(false);
    } catch (error) {
      console.error('Error during logout:', error);
      throw error;
    }
  };

  const checkProfileComplete = async (userId: string) => {
    const { data, error } = await supabase
      .from('taskers')
      .select('profile_completed')
      .eq('auth_id', userId)
      .single();

    if (error) {
      console.error('Error checking profile status:', error);
      return false;
    }

    return data?.profile_completed || false;
  };

  const value = {
    session,
    user,
    taskerId,
    isApproved,
    loading,
    isAuthenticated,
    isProfileComplete,
    isLoading,
    login,
    logout,
    updateUserStatus,
    checkProfileComplete,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
