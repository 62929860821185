import React, { useState, useEffect } from "react";
import { Button, Checkbox, Accordion, AccordionItem } from "@nextui-org/react";
import { Icon } from "@iconify/react";

interface Category {
  id: number;
  name: string;
}

interface CategoryGroup {
  name: string;
  categoryIcon: string;
  categories: Category[];
}

interface CategorySelectionStepProps {
  onCategoryChange: (categories: number[]) => void;
  onPrev: () => void;
  onSubmit: (categoryIds: number[]) => void;
  isLoading: boolean;
  setSelectedCategories: React.Dispatch<React.SetStateAction<number[]>>;
  selectedCategories: number[];
}

const categoryGroups: CategoryGroup[] = [
  {
    name: "Servicios del Hogar",
    categoryIcon: "fa6-solid:house",
    categories: [
      { id: 1, name: "Transporte / Despacho / Encomienda" },
      { id: 2, name: "Maestro Chasquilla" },
      { id: 4, name: "Fletes / Mudanzas" },
      { id: 5, name: "Chef" },
      { id: 6, name: "Limpieza y Organización" },
      { id: 9, name: "Jardinería" },
      { id: 10, name: "Mecánica" },
      { id: 14, name: "Gasfitería y Electricidad Sencilla" },
      { id: 15, name: "Instalación de Aparatos" },
      { id: 17, name: "Mascotas" },
    ],
  },
  {
    name: "Educación y Formación",
    categoryIcon: "fa6-solid:graduation-cap",
    categories: [{ id: 2, name: "Clases Particulares" }],
  },
  {
    name: "Fotografía y Medios",
    categoryIcon: "fa6-solid:camera",
    categories: [
      { id: 7, name: "Fotografía / Edición / Reels" },
      { id: 18, name: "Influencers" },
    ],
  },
  {
    name: "Tecnología y Diseño",
    categoryIcon: "fa6-solid:laptop-code",
    categories: [
      { id: 8, name: "Tecnología / Computación" },
      { id: 20, name: "Webs / Shopify / WordPress" },
    ],
  },
  {
    name: "Arte y Creatividad",
    categoryIcon: "fa6-solid:paint-brush",
    categories: [
      { id: 11, name: "Promotor y Modelaje" },
      { id: 13, name: "Diseño" },
      { id: 19, name: "Maquillaje y Peinado" },
      { id: 21, name: "Arquitectura / Ilustración" },
      { id: 22, name: "Diseño Gráfico" },
    ],
  },
  {
    name: "Salud y Bienestar",
    categoryIcon: "fa6-solid:heart-pulse",
    categories: [
      { id: 12, name: "Personal Trainer / Kinesiología" },
      { id: 16, name: "Salud y Cuidado de Adultos Mayores" },
    ],
  },
  {
    name: "Servicios Personales",
    categoryIcon: "fa6-solid:user",
    categories: [
      { id: 23, name: "Maquillaje" },
      { id: 24, name: "Peinado" },
    ],
  },
];

const CategorySelectionStep: React.FC<CategorySelectionStepProps> = ({
  onCategoryChange,
  onPrev,
  onSubmit,
  isLoading,
  selectedCategories: initialSelectedCategories,
  setSelectedCategories,
}) => {
  const [localSelectedCategories, setLocalSelectedCategories] = useState<number[]>(
    initialSelectedCategories
  );

  useEffect(() => {
    setLocalSelectedCategories(initialSelectedCategories);
  }, [initialSelectedCategories]);

  useEffect(() => {
    onCategoryChange(localSelectedCategories);
    setSelectedCategories(localSelectedCategories);
  }, [localSelectedCategories, onCategoryChange, setSelectedCategories]);

  const handleSubmit = () => {
    onSubmit(localSelectedCategories);
  };

  const handleCategoryToggle = (categoryId: number) => {
    setLocalSelectedCategories((prev) => {
      if (prev.includes(categoryId)) {
        return prev.filter((id) => id !== categoryId);
      } else if (prev.length < 5) {
        return [...prev, categoryId];
      }
      return prev;
    });
  };

  return (
    <div className="space-y-4 px-4 sm:px-0">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-center sm:text-left">
        Selecciona tus categorías (máximo 5)
      </h2>
      <Accordion className="mb-6">
        {categoryGroups.map((group) => {
          const groupCategories = group.categories;
          return (
            <AccordionItem
              key={group.name}
              aria-label={group.name}
              title={
                <div className="flex items-center space-x-2 py-2">
                  <Icon
                    icon={group.categoryIcon}
                    className="text-purple-500 text-xl"
                  />
                  <span className="text-base sm:text-lg">{group.name}</span>
                </div>
              }
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 p-2">
                {groupCategories.map((category) => (
                  <Checkbox
                    key={category.id}
                    isSelected={localSelectedCategories.includes(category.id)}
                    onValueChange={() => handleCategoryToggle(category.id)}
                    isDisabled={
                      !localSelectedCategories.includes(category.id) &&
                      localSelectedCategories.length >= 5
                    }
                    className="text-sm sm:text-base"
                  >
                    {category.name}
                  </Checkbox>
                ))}
              </div>
            </AccordionItem>
          );
        })}
      </Accordion>
      <div className="flex justify-between mt-8">
        <Button onClick={onPrev} color="secondary" className="py-2 px-4">
          Anterior
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          isLoading={isLoading}
          disabled={localSelectedCategories.length === 0}
          className="py-2 px-4 disabled:bg-gray-400"
        >
          Finalizar
        </Button>
      </div>
    </div>
  );
};

export default CategorySelectionStep;
