import React from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@nextui-org/react';
import { Tasks } from 'common/src/utils/types';

interface TaskDetailsModalProps {
  task: Tasks | null;
  isOpen: boolean;
  onClose: () => void;
  onApplyForTask: (taskId: number) => void;
}

const TaskDetailsModal: React.FC<TaskDetailsModalProps> = ({
  task,
  isOpen,
  onClose,
  onApplyForTask,
}) => {
  if (!task) return null;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <h3 style={{ fontSize: '1.125rem', fontWeight: '600' }}>
            {task.category}
          </h3>
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>Fecha:</strong> {new Date(task.date).toLocaleDateString()}
          </p>
          <p>
            <strong>Estado:</strong> {task.status}
          </p>
          <p>
            <strong>Descripción:</strong> {task.description}
          </p>
          {/* Agrega más detalles según sea necesario */}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="flat" onClick={onClose}>
            Cerrar
          </Button>
          <Button onClick={() => onApplyForTask(task.id)}>
            Aplicar para la Tarea
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TaskDetailsModal;
