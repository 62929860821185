import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Image } from '@nextui-org/react';
import { Tasks } from 'common/src/utils/types';
import { Camera } from '../../components/Camera/Camera';
import { fetchTask, updateTaskStatus, uploadTaskPhoto } from '../../api/tasks';
import StepProgress from '../../components/TaskExecution/StepProgress';

const TaskExecutionScreen: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const navigate = useNavigate();
  const [task, setTask] = useState<Tasks | null>(null);
  const [step, setStep] = useState(1);
  const [photo, setPhoto] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const loadTask = async () => {
      if (taskId) {
        const taskData = await fetchTask(taskId);
        setTask(taskData);
        const savedStep = localStorage.getItem(`taskStep_${taskId}`);
        if (savedStep) {
          setStep(parseInt(savedStep, 10));
        }
      }
    };
    loadTask();

    return () => {
      if (taskId) {
        localStorage.removeItem(`taskStep_${taskId}`);
      }
    };
  }, [taskId]);

  const handleStart = async () => {
    if (task) {
      await updateTaskStatus(task.id, 'in_progress');
      setStep(2);
      localStorage.setItem(`taskStep_${task.id}`, '2');
    }
  };

  const handleFinish = () => {
    setStep(3);
    localStorage.setItem(`taskStep_${task?.id}`, '3');
  };

  const handlePhotoCapture = (photoData: string) => {
    setPhoto(photoData);
  };

  const handleComplete = async () => {
    if (task && photo) {
      setIsUploading(true);
      try {
        const photoBlob = await fetch(photo).then(res => res.blob());
        await uploadTaskPhoto(task.id, photoBlob);
        await updateTaskStatus(task.id, 'completed');
        localStorage.removeItem(`taskStep_${task.id}`); // Remove the saved step when the task is completed
        navigate('/calendar');
      } catch (error) {
        console.error('Error completing task:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsUploading(false);
      }
    }
  };

  if (!task) return <div>Loading...</div>;

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-sm mt-8">
      <h1 className="text-3xl font-bold mb-6 text-center">{task.category}</h1>
      <StepProgress currentStep={step} totalSteps={3} />
      
      <Card className="p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Descripción de la tarea</h2>
        <p className="mb-4">{task.description}</p>
      </Card>

      {step === 1 && (
        <Card className="p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Paso 1: Iniciar Tarea</h2>
          <p className="mb-4">¿Estás listo para comenzar esta tarea?</p>
          <Button color="secondary" onClick={handleStart} size="lg" className="w-full bg-purple-500 text-white">
            Iniciar Tarea
          </Button>
        </Card>
      )}

      {step === 2 && (
        <Card className="p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Paso 2: Ejecutar Tarea</h2>
          <p className="mb-4">Completa la tarea según las instrucciones proporcionadas.</p>
          <Button color="secondary" onClick={handleFinish} size="lg" className="w-full bg-purple-500 text-white">
            Finalizar Tarea
          </Button>
        </Card>
      )}

      {step === 3 && !photo && (
        <Card className="p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Paso 3: Documentar Tarea</h2>
          <p className="mb-4">Por favor, tome una foto del trabajo terminado:</p>
          <Camera onCapture={handlePhotoCapture} />
        </Card>
      )}

      {photo && (
        <Card className="p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Tarea Completada</h2>
          <Image src={photo} alt="Tarea completada" className="mb-4 rounded-lg" />
          <Button 
            color="success" 
            onClick={handleComplete}
            disabled={isUploading}
            size="lg"
            className="w-full"
          >
            {isUploading ? 'Subiendo...' : 'Completar Tarea'}
          </Button>
        </Card>
      )}
    </div>
  );
};

export default TaskExecutionScreen;