import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterLeftColumn from '../../components/Register/RegisterLeftColumn';
import RegisterRightColumn from '../../components/Register/RegisterRightColumn';
import TaskerLoadingScreen from '../../components/TaskerProfileCreation/TaskerLoadingScreen';
import TaskerBasicInfo from '../../components/TaskerProfileCreation/TaskerBasicInfo';
import { supabase } from '../../supabase/supabase';
import { toast } from 'react-toastify';

interface RegisterProps {
  setView?: React.Dispatch<React.SetStateAction<'login' | 'register'>>;
}

const Register: React.FC<RegisterProps> = ({ setView } = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const [taskerInfo, setTaskerInfo] = useState({
    name: '',
    last_name: '',
    phone_number: '+56 ',
    university: '',
    career: '',
    university_year: '',
    rut: '',
    only_tasks_online: false,
  });

  const handleInitialRegistration = async (email: string, password: string) => {
    return new Promise<void>((resolve) => {
      setEmail(email);
      setPassword(password);
      setCurrentStep(2);
      resolve();
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setTaskerInfo((prev) => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Sign up the user
      const { data: { user }, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
      });

      if (signUpError) throw signUpError;
      if (!user) throw new Error('No se devolvieron datos de usuario');

      // Create a tasker profile
      const { data: tasker, error: taskerError } = await supabase
        .from('taskers')
        .insert([
          {
            auth_id: user.id,
            email: email,
            name: taskerInfo.name,
            last_name: taskerInfo.last_name,
            phone_number: taskerInfo.phone_number,
            university: taskerInfo.university,
            career: taskerInfo.career,
            university_year: taskerInfo.university_year,
            rut: taskerInfo.rut,
            only_tasks_online: taskerInfo.only_tasks_online,
            is_approved: false,
            profile_completed: true,
            application_status: 'pending_review',
          },
        ])
        .select()
        .single();

      if (taskerError) throw taskerError;
      if (!tasker) throw new Error('No se creó el perfil del tasker');

      toast.success("Perfil creado exitosamente");
      
      // Show loading screen for 4 seconds before refreshing
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } catch (error) {
      console.error('Error during registration:', error);
      toast.error("Error al crear el perfil. Por favor, intente nuevamente.");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <TaskerLoadingScreen />;
  }

  return (
    <div className="flex flex-col-reverse md:flex-row h-screen w-screen overflow-hidden">
      {currentStep === 1 ? (
        <RegisterLeftColumn 
          setView={setView} 
          onRegistrationSuccess={handleInitialRegistration}
          isLoading={isLoading} 
        />
      ) : (
        <TaskerBasicInfo
          taskerInfo={taskerInfo}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          handleFileChange={() => {}} // We're not handling file changes in this step
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setSelectedCategories={() => {}} // We're not handling categories in this step
          selectedCategories={[]}
          files={{
            certificate_regular_alumn: null,
            photo_selfie: null,
            certificate_criminal_record: null
          }}
          email={email}
          password={password}
        />
      )}
      <RegisterRightColumn />
    </div>
  );
};

export default Register;

