import React, { useState, useEffect } from "react";
import { Input, Button, Checkbox } from "@nextui-org/react";
import logoTasky from "../login/img/logo-tasky.svg";
import {
  handleRutChange,
  handlePhoneNumberChange,
} from "../../utils/functions";
import Swal from "sweetalert2";

type TaskerBasicInfoProps = {
  taskerInfo: {
    name: string;
    last_name: string;
    phone_number: string;
    university: string;
    career: string;
    university_year: string;
    rut: string;
    only_tasks_online: boolean;
  };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => Promise<void>;
  isLoading: boolean;
  handleFileChange: (name: string, file: File) => void;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedCategories: React.Dispatch<React.SetStateAction<number[]>>;
  selectedCategories: number[];
  files: {
    certificate_regular_alumn: File | null;
    photo_selfie: File | null;
    certificate_criminal_record: File | null;
  };
  email: string;
  password: string;
};

const TaskerBasicInfo: React.FC<TaskerBasicInfoProps> = ({
  taskerInfo,
  handleInputChange,
  handleSubmit,
  isLoading,
  handleFileChange,
  currentStep,
  setCurrentStep,
  setSelectedCategories,
  selectedCategories,
  files,
}) => {
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  useEffect(() => {
    const requiredFields = [
      "name",
      "last_name",
      "phone_number",
      "university",
      "career",
      "university_year",
      "rut",
    ];
    const profileComplete = requiredFields.every((field) => {
      const value = taskerInfo[field as keyof typeof taskerInfo];
      return typeof value === "string" && value.trim() !== "";
    });
    setIsProfileComplete(profileComplete);
  }, [taskerInfo]);

  const onSubmit = async () => {
    if (!isProfileComplete) {
      Swal.fire({
        icon: "warning",
        text: "Por favor, complete todos los campos requeridos antes de enviar su perfil.",
      });
    } else {
      await handleSubmit();
    }
  };

  return (
    <div className="flex flex-col w-full p-8 md:w-[480px] md:min-w-[480px]">
      <img src={logoTasky} alt="Logo" className="h-6 mb-4 self-start" />
      <div className="flex-grow flex items-center justify-center">
        <div className="w-full max-w-md px-2 sm:px-4 md:px-12">
          <div className="mb-6 mt-8 sm:mt-0 ">
            <h1 className="font-bold text-xl sm:text-2xl">
              Completa tu perfil de Tasker
            </h1>
          </div>
          <div className="space-y-6">
            <Input
              label="Nombre"
              name="name"
              value={taskerInfo.name}
              onChange={handleInputChange}
              className="w-full text-base sm:text-sm"
              required
            />
            <Input
              label="Apellido"
              name="last_name"
              value={taskerInfo.last_name}
              onChange={handleInputChange}
              className="w-full text-base sm:text-sm"
              required
            />
            <Input
              label="RUT"
              name="rut"
              value={taskerInfo.rut}
              onChange={(e) => handleRutChange(e, handleInputChange)}
              className="w-full text-base sm:text-sm"
              required
              placeholder="12.345.678-9"
            />
            <Input
              label="Número de teléfono"
              name="phone_number"
              value={taskerInfo.phone_number}
              onChange={(e) => handlePhoneNumberChange(e, handleInputChange)}
              className="w-full text-base sm:text-sm"
              required
              placeholder="+56 9 1234 5678"
              maxLength={16}
            />
            <Input
              label="Universidad o Instituto"
              name="university"
              value={taskerInfo.university}
              onChange={handleInputChange}
              className="w-full text-base sm:text-sm"
              required
            />
            <Input
              label="Carrera"
              name="career"
              value={taskerInfo.career}
              onChange={handleInputChange}
              className="w-full text-base sm:text-sm"
              placeholder="Ej: Ingeniería Civil Industrial"
              required
            />
            <Input
              label="Año universitario"
              name="university_year"
              value={taskerInfo.university_year}
              onChange={handleInputChange}
              className="w-full text-base sm:text-sm"
              required
              placeholder="Ej: 3"
            />
            <div className="flex items-center space-x-2">
              <Checkbox
                name="solo tareas en linea?"
                checked={taskerInfo.only_tasks_online}
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: "only_tasks_online",
                      value: e.target.checked,
                    },
                  } as unknown as React.ChangeEvent<HTMLInputElement>)
                }
                size="lg"
              />
              <label
                htmlFor="only_tasks_online"
                className="text-sm sm:text-base text-gray-600"
              >
                Solo tareas en línea
              </label>
            </div>
            <Button
              onClick={onSubmit}
              color="primary"
              className="w-full py-3 sm:py-2 text-base sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-800"
              disabled={!isProfileComplete || isLoading}
            >
              {isLoading ? "Cargando..." : "Finalizar"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskerBasicInfo;
