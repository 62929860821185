import React, { useState } from 'react';
import { OfferedTasksList } from '../../components/tasks/OfferedTaskList';
import { AcceptedTasksList } from '../../components/tasks/AcceptedTasksList/index';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { motion } from 'framer-motion';

const queryClient = new QueryClient();

const Tasks: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'accepted' | 'offered'>('accepted');

  return (
    <QueryClientProvider client={queryClient}>
      <div className="w-full h-screen bg-white p-4">
        <h1 className="text-2xl font-bold mb-4">Mis tasks</h1>
        <div className="flex justify-center sm:justify-start">
          <div className="relative mb-4 bg-gray-200 rounded-full p-1 w-full max-w-xs sm:max-w-[200px]">
            <motion.div
              className="absolute top-1 bottom-1 left-1 rounded-full bg-purple-500"
              initial={false}
              animate={{
                x: activeTab === 'accepted' ? 0 : '100%',
                width: '50%',
              }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            />
            <div className="relative flex">
              <button
                className={`z-10 flex-1 px-2 sm:px-4 py-2 text-xs sm:text-sm font-medium rounded-full ${
                  activeTab === 'accepted' ? 'text-white' : 'text-gray-700'
                }`}
                onClick={() => setActiveTab('accepted')}
              >
                Aceptadas
              </button>
              <button
                className={`z-10 flex-1 px-2 sm:px-4 py-2 text-xs sm:text-sm font-medium rounded-full ${
                  activeTab === 'offered' ? 'text-white' : 'text-gray-700'
                }`}
                onClick={() => setActiveTab('offered')}
              >
                Ofrecidas
              </button>
            </div>
          </div>
        </div>
        {activeTab === 'accepted' ? <AcceptedTasksList /> : <OfferedTasksList />}
      </div>
    </QueryClientProvider>
  );
};

export default Tasks;