import React, { useState } from 'react';
import { OfferedTaskCard } from '../OfferedTaskCard';
// import { Tasks } from '../../../utils/types';
import {
  fetchOfferedTasksForTasker,
  // acceptTask,
  rejectTask,
  applyForTask,
} from '../../../api/tasks';
import { useAuth } from '../../../hooks/useAuth';
import { Spinner } from '@nextui-org/react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import TaskDetailsModal from '../../TaskDetailModal/TaskDetailModal';
import { toast } from 'react-toastify';

export const OfferedTasksList: React.FC = () => {
  const { taskerId } = useAuth();
  const queryClient = useQueryClient();
  // const [selectedTask, setSelectedTask] = useState<Tasks | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<any>(null);

  const {
    data: taskOffers,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['offeredTasks', taskerId],
    queryFn: () => fetchOfferedTasksForTasker(taskerId?.toString() || ''),
    enabled: !!taskerId,
    select: (data) =>
      data.filter(
        (offer: { status: string }) =>
          offer.status === 'offered' || offer.status === 'pending',
      ),
  });

  // const acceptMutation = useMutation({
  //   mutationFn: (taskId: number) =>
  //     acceptTask(taskId, taskerId?.toString() || ''),
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({ queryKey: ['offeredTasks'] });
  //     queryClient.invalidateQueries({ queryKey: ['acceptedTasks'] });
  //     setIsModalOpen(false);
  //   },
  // });

  const rejectMutation = useMutation({
    mutationFn: (taskId: number) =>
      rejectTask(taskId, taskerId?.toString() || ''),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['offeredTasks', taskerId] });
      refetch(); // Refetch the tasks after rejecting
    },
  });

  const applyMutation = useMutation({
    mutationFn: (offerId: number) =>
      applyForTask(offerId, taskerId?.toString() || ''),
    onSuccess: (data) => {
      console.log('Mutation success data:', data);
      queryClient.invalidateQueries({ queryKey: ['offeredTasks'] });
      setIsModalOpen(false);
    },
    onError: (error) => {
      console.error('Mutation error:', error);
    },
  });

  // const handleAccept = (taskId: number) => {
  //   const task =
  //     taskOffers?.find((t: { id: number }) => t.id === taskId) || null;
  //   setSelectedTask(task as Tasks | null);
  //   setIsModalOpen(true);
  // };

  const handleReject = (taskId: number) => {
    rejectMutation.mutate(taskId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOffer(null);
  };

  // const handleStartTask = async (taskId: number) => {
  //   try {
  //     const success = await acceptMutation.mutateAsync(taskId);
  //     if (success) {
  //       queryClient.invalidateQueries({ queryKey: ['offeredTasks', taskerId] });
  //       queryClient.invalidateQueries({
  //         queryKey: ['acceptedTasks', taskerId],
  //       });
  //       handleCloseModal();
  //     } else {
  //       // Handle the case where the task couldn't be accepted (e.g., it was already taken)
  //       console.error('Failed to accept task');
  //     }
  //   } catch (error) {
  //     console.error('Error accepting task:', error);
  //   }
  // };

  const handleApplyForTask = async (offerId: number) => {
    console.log('handleApplyForTask called with offerId:', offerId);
    try {
      console.log('Attempting to apply for task...');
      const result = await applyMutation.mutateAsync(offerId);
      console.log('applyMutation complete result:', result);

      if (result) {
        console.log('Application successful, invalidating queries...');
        queryClient.invalidateQueries({ queryKey: ['offeredTasks', taskerId] });
        console.log('Queries invalidated');

        console.log('Closing modal...');
        handleCloseModal();

        console.log('Showing success toast');
        toast.success('Aplicación enviada exitosamente');
      } else {
        console.log('Application unsuccessful');
        toast.error(
          'No se pudo aplicar a la tarea. Por favor, intente nuevamente.',
        );
      }
    } catch (error) {
      console.error('Error applying for task:', error);
      console.log('Error details:', JSON.stringify(error, null, 2));
      toast.error(
        'Error al aplicar a la tarea. Por favor, intente nuevamente.',
      );
    }
    console.log('handleApplyForTask completed');
  };

  const handleApply = (offerId: number) => {
    const offer = taskOffers?.find((o) => o.id === offerId) || null;
    setSelectedOffer(offer);
    setIsModalOpen(true);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="lg" color="secondary" />
      </div>
    );
  }

  if (error)
    return (
      <div className="text-center text-red-500">
        Error al cargar las tareas ofrecidas
      </div>
    );

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mx-2 sm:mx-4 md:mx-6 lg:mx-8 my-4 sm:my-6 md:my-8">
        {taskOffers && taskOffers.length > 0 ? (
          taskOffers.map((offer) => (
            <OfferedTaskCard
              key={offer.id}
              taskOffer={offer}
              onApply={() => handleApply(offer.id)}
              onReject={() => handleReject(offer.id)}
            />
          ))
        ) : (
          <div className="col-span-full text-center text-gray-500 p-4">
            No hay tareas ofrecidas en este momento.
          </div>
        )}
      </div>
      <TaskDetailsModal
        task={selectedOffer ? selectedOffer.tasks : null}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onApplyForTask={() => {
          console.log('Selected offer:', selectedOffer);
          selectedOffer && handleApplyForTask(selectedOffer.id);
        }}
      />
    </>
  );
};
