import { Tasks } from 'common/src/utils/types';
import { supabase } from '../supabase/supabase';


export const fetchOfferedTasks = async (): Promise<Tasks[]> => {
  const { data, error } = await supabase
    .from('tasks')
    .select('*')
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data || [];
};

export const acceptTask = async (
  taskId: number,
  taskerId: string,
): Promise<boolean> => {
  const { data, error } = await supabase.rpc('accept_task_offer', {
    p_task_id: taskId,
    p_tasker_id: taskerId,
  });

  if (error) throw error;
  return data;
};

export const rejectTask = async (
  taskId: number,
  taskerId: string,
): Promise<void> => {
  const { error } = await supabase
    .from('task_offers')
    .update({ status: 'rejected' })
    .eq('task_id', taskId)
    .eq('tasker_id', taskerId);

  if (error) throw error;
};

export const fetchOfferedTasksForTasker = async (
  taskerId: string,
): Promise<any[]> => {
  try {
    const { data, error } = await supabase
      .from('task_offers')
      .select(
        `
        id,
        status,
        tasks (
          id,
          category,
          description,
          price,
          created_at,
          date,
          status
        )
      `,
      )
      .eq('tasker_id', taskerId)
      .in('status', ['offered', 'pending', 'applied']);

    if (error) {
      console.error('Error fetching offered tasks:', error);
      return [];
    }

    return data || [];
  } catch (error) {
    console.error('Error fetching offered tasks:', error);
    return [];
  }
};

export const fetchTasksForTasker = async (
  taskerId: string,
): Promise<Tasks[]> => {
  if (!taskerId) {
    console.error('Invalid taskerId provided');
    return [];
  }

  const { data: tasks, error } = await supabase
    .from('tasks')
    .select('*')
    .eq('tasker_id', taskerId)
    .gte('status', 'accepted');

  if (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }

  return tasks || [];
};

export const fetchTask = async (taskId: string): Promise<Tasks> => {
  const { data, error } = await supabase
    .from('tasks')
    .select('*')
    .eq('id', taskId)
    .single();

  if (error) throw error;
  return data;
};

export const updateTaskStatus = async (
  taskId: number,
  status: string,
): Promise<void> => {
  const { error } = await supabase
    .from('tasks')
    .update({ status })
    .eq('id', taskId);

  if (error) throw error;
};

export const uploadTaskPhoto = async (
  taskId: number,
  photo: Blob,
): Promise<string> => {
  try {
    const fileName = `task_${taskId}_${Date.now()}.jpg`;
    const filePath = `pruebas_tasks_terminadas/${fileName}`;

    const { data, error } = await supabase.storage
      .from('dev')
      .upload(filePath, photo, {
        contentType: 'image/jpeg',
        upsert: false,
      });
    console.log('data', data);

    if (error) {
      console.error('Supabase storage error:', error);
      throw new Error('Error uploading photo: ' + error.message);
    }

    const { data: urlData } = supabase.storage
      .from('dev')
      .getPublicUrl(filePath);

    const photoUrl = urlData.publicUrl;

    const { error: updateError } = await supabase
      .from('tasks')
      .update({ task_completed_proof_url: photoUrl })
      .eq('id', taskId);

    if (updateError)
      throw new Error(
        'Error updating task with photo URL: ' + updateError.message,
      );

    return photoUrl;
  } catch (error) {
    console.error('Unexpected error in uploadTaskPhoto:', error);
    throw error;
  }
};

export const applyForTask = async (
  offerId: number,
  taskerId: string,
): Promise<boolean> => {
  console.log('Applying for task:', { offerId, taskerId });
  try {
    const { data, error } = await supabase
      .from('task_offers')
      .update({ status: 'applied' })
      .eq('id', offerId)
      .eq('tasker_id', taskerId)
      .select()
      .single();

    if (error) {
      console.error('Supabase error applying for task:', error);
      return false;
    }

    console.log('Apply for task result:', data);
    return !!data;
  } catch (error) {
    console.error('Unexpected error applying for task:', error);
    return false;
  }
};
