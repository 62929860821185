import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Select, ModalHeader, ModalBody, ModalFooter, SelectItem, ModalContent, Divider, Card, CardBody, Accordion, AccordionItem } from '@nextui-org/react';
import { CategoryConfig } from '../../config/CategoryConfig';
import { supabase } from '../../supabase/supabase';

interface CategoryInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (info: any) => void;
  category: {
    id: number;
    name: string;
    info: any;
  };
}

const CategoryInfoModal: React.FC<CategoryInfoModalProps> = ({
  isOpen,
  onClose,
  onSave,
  category,
}) => {
  const [categoryInfo, setCategoryInfo] = useState<any>({});

  useEffect(() => {
    if (category) {
      setCategoryInfo(category.info || {});
    }
  }, [category]);

  const handleInputChange = (name: string, value: string | string[]) => {
    setCategoryInfo((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(categoryInfo);
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const file = e.target.files?.[0];
    if (file) {
      const { data, error } = await supabase.storage
        .from('category-documents')
        .upload(`${category.id}/${fieldName}/${file.name}`, file);

      if (error) {
        console.error('Error uploading file:', error);
      } else if (data) {
        const { data: { publicUrl } } = supabase.storage
          .from('category-documents')
          .getPublicUrl(data.path);

        if (publicUrl) {
          handleInputChange(fieldName, publicUrl);
        }
      }
    }
  };

  const renderFields = () => {
    return CategoryConfig[category.name].fields.map((field) => {
      if (field.condition) {
        const { field: conditionField, value: conditionValue } = field.condition;
        const currentValue = categoryInfo[conditionField];
        if (Array.isArray(conditionValue) ? !conditionValue.includes(currentValue) : currentValue !== conditionValue) {
          return null;
        }
      }
      return renderField(field);
    });
  };

  const renderField = (field: any) => {
    switch (field.type) {
      case 'text':
      case 'date':
        return (
          <Input
            key={field.name}
            label={field.label}
            name={field.name}
            type={field.type}
            value={categoryInfo[field.name] || ''}
            onChange={(e) => handleInputChange(field.name, e.target.value)}
            placeholder={field.placeholder || ''}
            className="w-full"
          />
        );
      case 'select':
        return (
          <Select
            key={field.name}
            label={field.label}
            value={categoryInfo[field.name] || ''}
            onChange={(e) => handleInputChange(field.name, e.target.value)}
            placeholder={field.placeholder || 'Seleccione una opción'}
            className="w-full"
          >
            {field.options?.map((option: any) => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            )) || []}
          </Select>
        );
      case 'multi-select':
        return (
          <Select
            key={field.name}
            label={field.label}
            selectionMode="multiple"
            value={categoryInfo[field.name] || []}
            onChange={(e) => handleInputChange(field.name, e.target.value)}
            placeholder={field.placeholder || 'Seleccione una o más opciones'}
            className="w-full"
          >
            {field.options?.map((option: any) => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            )) || []}
          </Select>
        );
      case 'textarea':
        return (
          <Input
            key={field.name}
            type="textarea"
            label={field.label}
            value={categoryInfo[field.name] || ''}
            onChange={(e) => handleInputChange(field.name, e.target.value)}
            placeholder={field.placeholder || ''}
            className="w-full"
          />
        );
      case 'file':
        return (
          <div key={field.name} className="space-y-2 w-full">
            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <div className="flex items-center space-x-2">
              <Button
                as="label"
                htmlFor={field.name}
                color="primary"
                variant="flat"
                className="cursor-pointer w-full sm:w-auto"
              >
                Seleccionar archivo
              </Button>
              <span className="text-sm text-gray-500 truncate flex-1">
                {categoryInfo[field.name]
                  ? categoryInfo[field.name].split('/').pop()
                  : 'Ningún archivo seleccionado'}
              </span>
            </div>
            <Input
              id={field.name}
              key={field.name}
              type="file"
              onChange={(e) => handleFileUpload(e, field.name)}
              accept={field.accept || '.pdf,.jpg,.jpeg,.png'}
              className="hidden"
            />
          </div>
        );
      case 'group':
        return (
          <Accordion key={field.name} className="w-full">
            <AccordionItem
              key={field.name}
              aria-label={field.label}
              title={field.label}
              className="bg-gray-50 rounded-lg mb-4"
            >
              <div className="space-y-3 p-2">
                {field.fields?.map((subField: any) => (
                  <div key={subField.name} className="bg-white rounded p-3 shadow-sm">
                    {renderField(subField)}
                  </div>
                ))}
              </div>
            </AccordionItem>
          </Accordion>
        );
      default:
        return null;
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              <h2 className="text-xl sm:text-2xl font-bold">Editar información de {category.name}</h2>
              <p className="text-xs sm:text-sm">Complete los siguientes campos para actualizar su información</p>
            </ModalHeader>
            <Divider />
            <ModalBody>
              <div className="space-y-4 sm:space-y-6 max-w-3xl mx-auto">
                {renderFields()}
              </div>
            </ModalBody>
            <Divider />
            <ModalFooter>
              <Button color="danger" onClick={onClose} className="w-full sm:w-auto">
                Cancelar
              </Button>
              <Button onClick={handleSave} color="primary" className="w-full sm:w-auto">
                Guardar
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CategoryInfoModal;
