import React from 'react';

interface ProfileItemProps {
  icon: React.ReactNode;
  label: string;
  value: React.ReactNode;
}

const ProfileItem: React.FC<{ icon: React.ReactNode; label: string; value: React.ReactNode }> = ({ icon, label, value }) => (
  <div className="flex items-center">
    <div className="text-gray-500 mr-2">{icon}</div>
    <div>
      <p className="text-sm text-gray-500">{label}</p>
      <p className="text-sm">{value}</p>
    </div>
  </div>
);

export default ProfileItem;
