import { Auth } from '@supabase/auth-ui-react';
// import { supabase } from 'common/src/supabase';
import { supabase } from '../../supabase/supabase';
import logoTasky from '../../components/login/img/logo-tasky.svg';
import { customAppearence } from './constants';
import { useState } from 'react';

interface LoginLeftColumnProps {
  setView: (view: 'login' | 'register') => void;
  onLogin: (email: string, password: string) => Promise<void>;
  isLoading: boolean;
}

const LoginLeftColumn: React.FC<LoginLeftColumnProps> = ({ setView, onLogin, isLoading }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onLogin(email, password);
  };

  return (
    <div className="flex flex-col w-full md:w-1/4 p-4 md:p-8 bg-white">
      <img src={logoTasky} alt="Logo" className="h-6 mb-4 self-start" />
      <div className="flex-grow flex items-center justify-center">
        <div className="w-full max-w-md px-4 md:px-12">
          <div className="mb-6">
            <h1 className="font-bold text-xl md:text-2xl">
              Bienvenid@ de vuelta!
            </h1>
            <p className="text-sm text-gray-700">
              Por favor, ingresa tus credenciales
            </p>
          </div>
          <Auth
            supabaseClient={supabase as any}
            appearance={customAppearence}
            providers={[]}
            view="sign_in"
            showLinks={false}
            localization={{
              variables: {
                sign_in: {
                  email_label: 'Correo electrónico',
                  password_label: 'Contraseña',
                  button_label: 'Iniciar sesión',
                  email_input_placeholder: 'Tu correo electrónico',
                  password_input_placeholder: 'Tu contraseña',
                },
              },
            }}
          />
          <div className="flex justify-end">
            <p className="mt-4 text-center text-sm md:text-base text-gray-500 opacity-70">
              ¿No tienes cuenta?{' '}
              <button
                className="underline text-gray-800 hover:text-black"
                onClick={() => setView('register')}
              >
                Regístrate
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLeftColumn;
