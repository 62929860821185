import loginHero from '../../components/login/img/login-hero.png';

const LoginRightColumn = () => {
  return (
    <div className="hidden md:flex w-full md:w-3/4 bg-purple-500 items-center justify-center p-4 md:p-8 relative">
      <div className="w-full h-full flex flex-col justify-center items-center">
        <img
          src={loginHero}
          alt=""
          className="w-full max-w-[30rem] object-contain mb-4"
        />
        <p className="italic font-bold text-lg md:text-2xl text-purple-50 uppercase w-full text-left absolute bottom-8 left-8">
          Únete a Tasky. <br /> Resuelve tasks y gana dinero.
        </p>
      </div>
    </div>
  );
};

export default LoginRightColumn;
