import React, { useState } from 'react';
import { Card, CardBody, Button } from '@nextui-org/react';
import { Tasks } from 'common/src/utils/types';
import { useNavigate } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';

interface TaskCardViewProps {
  tasks: Tasks[];
}

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'decimal',
  }).format(price);
};

const TaskCardView: React.FC<TaskCardViewProps> = ({ tasks }) => {
  const navigate = useNavigate();

  const handleStartTask = (taskId: number) => {
    navigate(`/task-execution/${taskId}`);
  };

  if (tasks.length === 0) {
    return <div>No tasks available.</div>;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {tasks.map(task => (
        <Card key={task.id} className="w-full shadow-md rounded-2xl overflow-hidden bg-gradient-to-br from-purple-400 to-purple-500 relative">
          <CardBody className="p-4 sm:p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start mb-4">
              <div className="flex flex-col mb-2 sm:mb-0">
                <h3 className="text-xl sm:text-2xl font-bold text-white mb-1">{task.category}</h3>
                <div className="text-sm text-white opacity-80">{new Date(task.date).toLocaleDateString('es-ES')}</div>
              </div>
              <div className="bg-white rounded-full p-2 w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 sm:w-6 sm:h-6 text-purple-500">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
            </div>
            <p className="text-sm text-white mb-4 line-clamp-2">
              {task.description}
            </p>
            <p className="text-lg sm:text-xl font-bold text-white">Precio: ${formatPrice(task.price)}</p>
          </CardBody>
          <div className="absolute bottom-4 right-4">
            <Button
              isIconOnly
              className="w-12 h-12 rounded-full bg-white bg-opacity-20 backdrop-blur-sm hover:bg-opacity-30 transition-colors border-2 border-white"
              onPress={() => handleStartTask(task.id)}
            >
              <FaPlay className="text-white" />
            </Button>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default TaskCardView;