import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import Layout from '../components/Layout/Layout';
import AuthPage from '../pages/auth';
import TaskerProfileCreation from '../pages/CreateProfile/TaskerProfileCreation';
import PendingApproval from '../pages/PendingApproval/PendingApproval';
import Home from '../pages/Home';
import Tasks from '../pages/Tasks';
import Profile from '../pages/Profile';
import Calendar from '../pages/Calendar';
import TaskExecutionScreen from '../pages/TaskExecutionScreen';
import AcceptedTasksPage from '../pages/AcceptedTasksPage';
import TaskerInformation from '../pages/Dev/TaskerInformation';
import TaskerCategoryDetails from '../pages/TaskerCategoryDetails/TaskerCategoryDetails';

const AppRoutes: React.FC = () => {
  const { session, loading, taskerId, isApproved, isProfileComplete } = useAuth();

  if (loading) {
    return <LoadingScreen />;
  }

  if (!session) {
    return <AuthPage />;
  }

  if (!taskerId) {
    return <LoadingScreen />;
  }

  if (!isProfileComplete) {
    return <TaskerProfileCreation />;
  }

  if (!isApproved) {
    return (
      <Layout>
        <Routes>
          <Route path="/detalles-tasker" element={<TaskerCategoryDetails />} />
          <Route path="*" element={<Navigate to="/detalles-tasker" replace />} />
        </Routes>
      </Layout>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/task-execution/:taskId" element={<TaskExecutionScreen />} />
        <Route path="/accepted-tasks" element={<AcceptedTasksPage />} />
        <Route path="/dev-tasker-information" element={<TaskerInformation />} />
        <Route path="/detalles-tasker" element={<TaskerCategoryDetails />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
};

export default AppRoutes;
