import React, { useState, useEffect } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input } from "@nextui-org/react";

interface ProfileEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedInfo: any) => void;
  profileInfo: any;
}

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({ isOpen, onClose, onSave, profileInfo }) => {
  const [editedInfo, setEditedInfo] = useState(profileInfo);

  useEffect(() => {
    setEditedInfo(profileInfo);
  }, [profileInfo]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedInfo((prev: typeof profileInfo) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onSave(editedInfo);
    onClose();
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xl"
      scrollBehavior="inside"
      className="sm:max-w-md md:max-w-lg lg:max-w-xl"
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Editar Perfil</ModalHeader>
        <ModalBody>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Input
              label="Nombre"
              name="name"
              value={editedInfo.name}
              onChange={handleInputChange}
            />
            <Input
              label="Apellido"
              name="last_name"
              value={editedInfo.last_name}
              onChange={handleInputChange}
            />
            <Input
              label="Teléfono"
              name="phone_number"
              value={editedInfo.phone_number}
              onChange={handleInputChange}
            />
            <Input
              label="Universidad"
              name="university"
              value={editedInfo.university}
              onChange={handleInputChange}
            />
            <Input
              label="Carrera"
              name="career"
              value={editedInfo.career}
              onChange={handleInputChange}
            />
            <Input
              label="Año Universitario"
              name="university_year"
              value={editedInfo.university_year}
              onChange={handleInputChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose} className="w-full sm:w-auto">
            Cancelar
          </Button>
          <Button color="primary" onPress={handleSubmit} className="w-full sm:w-auto">
            Guardar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProfileEditModal;