import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginLeftColumn from '../../components/login/LoginLeftColumn';
import LoginRightColumn from '../../components/login/LoginRightColumn';
import { useAuth } from '../../hooks/useAuth';
import { supabase } from '../../supabase/supabase';

type props = {
  setView?: (view: 'login' | 'register') => void;
};

export default function Login({ setView }: props = {}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login, isAuthenticated, isProfileComplete, isApproved } = useAuth();

  const handleLogin = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      await login(email, password);
      const { data: tasker, error } = await supabase
        .from('taskers')
        .select('profile_completed, is_approved')
        .eq('email', email)
        .single();

      if (error) throw error;

      if (!tasker.profile_completed) {
        navigate('/tasker/create-profile');
      } else if (!tasker.is_approved) {
        navigate('/pending-approval');
      } else {
        navigate('/tasker/dashboard');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Error durante el inicio de sesión. Por favor, intente nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col-reverse md:flex-row h-screen w-screen overflow-hidden">
      <LoginLeftColumn setView={setView || (() => {})} onLogin={handleLogin} isLoading={isLoading} />
      <LoginRightColumn />
    </div>
  );
}
