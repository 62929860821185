import React, { useState, useEffect } from 'react';
import { Input, Button } from '@nextui-org/react';
import { toast } from 'react-toastify';
import logoTasky from '../login/img/logo-tasky.svg';
import posthog from 'posthog-js';

interface RegisterLeftColumnProps {
  onRegistrationSuccess: (email: string, password: string) => Promise<void>;
  isLoading: boolean;
  setView?: React.Dispatch<React.SetStateAction<'login' | 'register'>>;
}

const RegisterLeftColumn: React.FC<RegisterLeftColumnProps> = ({
  onRegistrationSuccess,
  isLoading,
  setView,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    number: false,
  });

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    return {
      length: password.length >= minLength,
      uppercase: hasUpperCase,
      number: hasNumber,
    };
  };

  useEffect(() => {
    setPasswordStrength(validatePassword(password));
  }, [password]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!Object.values(passwordStrength).every(Boolean)) {
      toast.error('La contraseña no cumple con todos los requisitos.');
      return;
    }
    try {
      await onRegistrationSuccess(email, password);
      toast.success('Registro exitoso!');

      // Identify user with PostHog
      posthog.identify(email, {
        email: email,
        $set: { date_signed_up: new Date().toISOString() },
      });

      // Capture the signup event
      posthog.capture('user_signed_up', {
        email: email,
        signup_method: 'email',
      });
    } catch (error) {
      toast.error('Error al registrarse. Por favor, inténtalo de nuevo.');

      // Capture the failed signup attempt
      posthog.capture('signup_error', {
        email: email,
        error: error,
      });
    }
  };

  return (
    <div className="flex flex-col w-full md:w-1/4 p-4 md:p-8 bg-white">
      <img src={logoTasky} alt="Logo" className="h-6 mb-4 self-start" />
      <div className="flex-grow flex items-center justify-center">
        <div className="w-full max-w-md px-4 md:px-12">
          <div className="mb-6">
            <h1 className="font-bold text-xl md:text-2xl">
              Crea tu cuenta en Tasky
            </h1>
            <p className="text-sm text-gray-700">
              Ingresa tus datos para registrarte!
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              label="Correo electrónico"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                posthog.capture('email_input', { email: e.target.value });
              }}
              className="mb-4"
              required
            />
            <Input
              label="Contraseña"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                posthog.capture('password_input', {
                  password_length: e.target.value.length,
                  password_strength: validatePassword(e.target.value),
                });
              }}
              className="mb-2"
              required
            />
            <div className="mb-4">
              <p className="text-xs text-gray-600 mb-2">
                Requisitos de la contraseña:
              </p>
              <ul className="text-xs">
                <li
                  className={
                    passwordStrength.length ? 'text-green-500' : 'text-red-500'
                  }
                >
                  {passwordStrength.length ? '✓' : '✗'} Al menos 8 caracteres
                </li>
                <li
                  className={
                    passwordStrength.uppercase
                      ? 'text-green-500'
                      : 'text-red-500'
                  }
                >
                  {passwordStrength.uppercase ? '✓' : '✗'} Al menos una
                  mayúscula
                </li>
                <li
                  className={
                    passwordStrength.number ? 'text-green-500' : 'text-red-500'
                  }
                >
                  {passwordStrength.number ? '✓' : '✗'} Al menos un número
                </li>
              </ul>
            </div>
            <Button
              color="secondary"
              type="submit"
              className="w-full bg-purple-600 text-white"
              disabled={
                isLoading || !Object.values(passwordStrength).every(Boolean)
              }
            >
              {isLoading ? 'Cargando...' : 'Registrarse'}
            </Button>
          </form>
          <div className="flex justify-end">
            <p className="mt-4 text-center text-sm md:text-base text-gray-500 opacity-70">
              ¿Ya tienes cuenta?{' '}
              <button
                className="underline text-gray-800 hover:text-black"
                onClick={() => setView && setView('login')}
              >
                Inicia sesión
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterLeftColumn;
