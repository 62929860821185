import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import { fetchTasksForTasker } from '../../api/tasks';
import { fetchTaskerById } from '../../api/taskers'; // Add this import
import DashboardCard from '../../components/Dashboard/DashboardCard';
import Convenios from '../../components/Dashboard/Convenios';
import { Spinner } from '@nextui-org/react';

const Home: React.FC = () => {
  const { taskerId } = useAuth();

  const { data: tasks, isLoading: tasksLoading, error: tasksError } = useQuery({
    queryKey: ['allTasks', taskerId],
    queryFn: () => fetchTasksForTasker(taskerId?.toString() || ''),
    enabled: !!taskerId,
  });

  const { data: tasker, isLoading: taskerLoading, error: taskerError } = useQuery({
    queryKey: ['tasker', taskerId],
    queryFn: () => fetchTaskerById(taskerId || 0),
    enabled: !!taskerId,
  });

  const isLoading = tasksLoading || taskerLoading;
  const error = tasksError || taskerError;

  // const convenios = [
  //   // ... (keep your existing convenios data)
  //   {
  //     id: 1,
  //     name: 'Convenio 1',
  //     description: 'Descripción del convenio 1',
  //     image: 'https://via.placeholder.com/150',
  //     discount: '10%',
  //   },
  //   {
  //     id: 2,
  //     name: 'Convenio 2',
  //     description: 'Descripción del convenio 2',
  //     image: 'https://via.placeholder.com/700', 
  //     discount: '10%',
  //   },
  //   {
  //     id: 3,
  //     name: 'Convenio 3',
  //     description: 'Descripción del convenio 3',
  //     image: 'https://via.placeholder.com/700', 
  //     discount: '10%',
  //   },
  // ];

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="lg" color="secondary" />
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-500">Error al cargar los datos</div>;
  }

  const completedTasks = tasks?.filter(task => task.status === 'complete' || 'completed') || [];
  const pendingTasks = tasks?.filter(task => ['accepted', 'in_progress', 'assigned'].includes(task.status)) || [];
  const offeredTasks = tasks?.filter(task => !task.tasker_id && task.status === 'pending') || [];

  return (
    <div className="w-full h-full bg-white rounded-lg shadow-sm p-6">
      <h1 className="text-2xl font-bold mb-2 text-purple-600">
        Hola {tasker?.name} {tasker?.last_name}
      </h1>
      <p className="text-lg mb-6 text-gray-600">Este es tu resumen de Tasky</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <DashboardCard
          title="Tasks Completadas"
          count={completedTasks.length}
          description="Aquí puedes ver un resumen de tus tasks completadas."
          link="/calendar"
        />
        <DashboardCard
          title="Tasks por Completar"
          count={pendingTasks.length}
          description="Aquí puedes ver las tareas que tienes pendientes por completar."
          link="/pending-tasks"
        />
        <DashboardCard
          title="Tareas Ofrecidas"
          count={offeredTasks.length}
          description="Aquí puedes ver las tareas que te han sido ofrecidas."
          link="/offered-tasks"
        />
      </div>
      {/* <Convenios convenios={convenios.map(convenio => ({
        ...convenio,
        imageUrl: convenio.image,
        discount: convenio.discount ? convenio.discount.toString() : '0%' // Ensure discount is always a string
      }))} /> */}
      
    </div>
  );
};

export default Home;