import React from 'react';
import { Card, CardBody, Button } from '@nextui-org/react';
import { Tasks } from '../../../utils/types';
import { FaCheck, FaTimes } from 'react-icons/fa';

interface OfferedTaskCardProps {
  taskOffer: {
    id: number;
    status: string;
    tasks: Tasks;
  };
  onApply: (offerId: number) => void;
  onReject: (offerId: number) => void;
}

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'decimal',
  }).format(price);
};

export const OfferedTaskCard: React.FC<OfferedTaskCardProps> = ({ taskOffer, onApply, onReject }) => {
  const { tasks: task } = taskOffer;

  const formattedDate = new Date(task.created_at).toLocaleDateString('es-ES', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  const day = new Date(task.created_at).getDate();
  const monthYear = formattedDate.split(' ').slice(1).join(' ');

  return (
    <Card className="w-full shadow-md rounded-2xl overflow-hidden bg-gradient-to-br from-purple-400 to-purple-500 relative">
      <CardBody className="p-3 sm:p-4 md:p-6">
        <div className="flex flex-col sm:flex-row justify-between items-start mb-2 sm:mb-4">
          <div className="flex flex-col mb-2 sm:mb-0">
            <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-white mb-1">{task.category}</h3>
            <div className="text-xs sm:text-sm text-white opacity-80">{`${day} ${monthYear}`}</div>
          </div>
          <div className="bg-white rounded-full p-1 sm:p-2 w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 text-purple-500">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>
          </div>
        </div>
        <p className="text-xs sm:text-sm text-white mb-2 sm:mb-4 line-clamp-2">
          {task.description}
        </p>
        <p className="text-base sm:text-lg md:text-xl font-bold text-white">Precio: ${formatPrice(task.price)}</p>
      </CardBody>
      <div className="absolute bottom-4 right-4 flex space-x-2">
        <Button
          isIconOnly
          className="w-12 h-12 rounded-full bg-white bg-opacity-20 backdrop-blur-sm hover:bg-opacity-30 transition-colors border-2 border-white"
          onPress={() => onApply(taskOffer.id)}
        >
          <FaCheck className="text-white" />
        </Button>
        <Button
          isIconOnly
          className="w-12 h-12 rounded-full bg-white bg-opacity-20 backdrop-blur-sm hover:bg-opacity-30 transition-colors border-2 border-white"
          onPress={() => onReject(taskOffer.id)}
        >
          <FaTimes className="text-white" />
        </Button>
      </div>
    </Card>
  );
};