export const handleRutChange = (e: React.ChangeEvent<HTMLInputElement>, handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
  const input = e.target.value;
  const numericInput = input.replace(/\D/g, '');
  let formattedRut = '';

  if (numericInput.length <= 1) {
    formattedRut = numericInput;
  } else {
    const body = numericInput.slice(0, -1);
    const dv = numericInput.slice(-1);
    formattedRut = body.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '-' + dv;
  }

  handleInputChange({
    target: {
      name: 'rut',
      value: formattedRut,
    },
  } as React.ChangeEvent<HTMLInputElement>);
};

export const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>, handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
  let input = e.target.value.replace(/\D/g, '');
  if (input.length > 0 && !input.startsWith('56')) {
    input = '56' + input;
  }
  if (input.length > 11) {
    input = input.slice(0, 11);
  }
  const formattedNumber = input.length > 2 ? `+${input.slice(0, 2)} ${input.slice(2)}` : `+${input}`;
  handleInputChange({
    target: {
      name: 'phone_number',
      value: formattedNumber,
    },
  } as React.ChangeEvent<HTMLInputElement>);
};
