import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Card, CardBody, Avatar, Button } from '@nextui-org/react';
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaUniversity,
  FaBriefcase,
  FaCertificate,
} from 'react-icons/fa';
import { fetchTaskerById } from '../../api/taskers';
import { Tasker } from 'common/src/utils/types';

const Profile: React.FC = () => {
  const { taskerId } = useAuth();
  const [tasker, setTasker] = useState<Tasker | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTasker = async () => {
      if (taskerId) {
        try {
          const fetchedTasker = await fetchTaskerById(taskerId);
          setTasker(fetchedTasker);
        } catch (error) {
          console.error('Error cargando el tasker:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadTasker();
  }, [taskerId]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (!tasker) {
    return <div>Tasker no encontrado</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Tu Perfil</h1>
      <div className="flex flex-col md:flex-row md:space-x-6">
        <Card className="w-full md:w-1/3 mx-auto mb-6 md:mb-0">
          <CardBody className="flex flex-col items-center justify-between p-6 h-full">
            <div className="flex-grow flex items-center justify-center">
              <Avatar
                src={tasker.photo_selfie_url || 'https://placehold.co/600x400'}
                className="w-40 h-40 sm:w-60 sm:h-60 md:w-80 md:h-80 text-6xl mb-4"
              />
            </div>
            <h2 className="text-4xl font-semibold mb-4 text-center">{`${tasker.name} ${tasker.last_name}`}</h2>
          </CardBody>
        </Card>
        <Card className="w-full md:w-2/3 mx-auto mt-8">
          <CardBody className="flex flex-col p-8">
            <div className="w-full space-y-4">
              <ProfileItem
                icon={<FaEnvelope />}
                label="Correo"
                value={tasker.email}
              />
              <ProfileItem
                icon={<FaPhone />}
                label="Teléfono"
                value={tasker.phone_number || 'No proporcionado'}
              />
              <ProfileItem
                icon={<FaUniversity />}
                label="Universidad"
                value={tasker.university}
              />
              <ProfileItem
                icon={<FaBriefcase />}
                label="Carrera"
                value={tasker.carreer}
              />
              <ProfileItem
                icon={<FaUser />}
                label="Categorías de Tareas"
                value={
                  tasker.task_categories
                    ? tasker.task_categories.join(', ')
                    : 'No proporcionado'
                }
              />
              <ProfileItem
                icon={<FaCertificate />}
                label="Certificado de Alumno Regular"
                value={
                  <Button
                    color="primary"
                    className="bg-purple-500 text-white"
                    onClick={() =>
                      window.open(
                        tasker.certificate_regular_alumn_url,
                        '_blank',
                      )
                    }
                  >
                    Ver Certificado
                  </Button>
                }
              />
              <ProfileItem
                icon={<FaCertificate />}
                label="Certificado de Antecedentes Penales"
                value={
                  <Button
                    color="primary"
                    className="bg-purple-500 text-white"
                    onClick={() =>
                      window.open(
                        tasker.certificate_criminal_record_url,
                        '_blank',
                      )
                    }
                  >
                    Ver Certificado
                  </Button>
                }
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const ProfileItem: React.FC<{
  icon: React.ReactNode;
  label: string;
  value: React.ReactNode;
}> = ({ icon, label, value }) => (
  <div className="flex items-center">
    <div className="text-gray-500 mr-2">{icon}</div>
    <div>
      <p className="text-sm text-gray-500">{label}</p>
      <p className="font-medium">{value}</p>
    </div>
  </div>
);

export default Profile;
