import React from 'react';
import { NextUIProvider } from '@nextui-org/react';
import { BrowserRouter as Router } from 'react-router-dom';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import AppRoutes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './contexts/AuthContext';

// PostHog initialization
if (typeof window !== 'undefined') {
  posthog.init('phc_g6HNvKKA5PBpLPJHNORV3NYSeBAS8KyM3YMh84HTsVH', {
    api_host: 'https://app.posthog.com',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
  });
}

const queryClient = new QueryClient();

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <PostHogProvider client={posthog}>
          <NextUIProvider>
            <Router>
              <ToastContainer />
              <AppRoutes />
            </Router>
          </NextUIProvider>
        </PostHogProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
