import React, { useEffect, useState } from 'react';
import TaskCardView from '../../components/TaskCardsView/taskCardView';
import { Tasks } from 'common/src/utils/types';
import { fetchTasksForTasker } from '../../api/tasks';
import { useAuth } from '../../hooks/useAuth';

const AcceptedTasksPage: React.FC = () => {
  const [tasks, setTasks] = useState<Tasks[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { taskerId } = useAuth();

  useEffect(() => {
    const loadTasks = async () => {
      if (taskerId) {
        setIsLoading(true);
        try {
          const tasksData = await fetchTasksForTasker(taskerId.toString());
          const acceptedTasks = tasksData.filter(task => task.status === 'accepted');
          setTasks(acceptedTasks);
        } catch (error) {
          console.error('Error fetching tasks:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadTasks();
  }, [taskerId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full h-full bg-white rounded-lg shadow-sm p-6">
      <h1 className="text-2xl font-bold mb-6">Tareas Aceptadas</h1>
      <TaskCardView tasks={tasks} />
    </div>
  );
};

export default AcceptedTasksPage;