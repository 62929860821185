import React from 'react';
import { Modal, Button, Avatar, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import { useAuth } from '../../hooks/useAuth';
import { fetchTaskerById } from '../../api/taskers';
import { FaEnvelope, FaPhone, FaUniversity, FaBriefcase, FaCertificate } from 'react-icons/fa';
import { Tasker } from 'src/utils/types';
import ProfileItem from './ProfileItem';

const ProfileModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { taskerId, logout } = useAuth();
  const [tasker, setTasker] = React.useState<Tasker | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const loadTasker = async () => {
      if (taskerId) {
        try {
          const fetchedTasker = await fetchTaskerById(taskerId);
          if (fetchedTasker) {
            setTasker((prevTasker) => ({
              ...prevTasker,
              ...fetchedTasker,
              career: prevTasker?.career || '',
              auth_id: prevTasker?.auth_id || ''
            }));
          } else {
            console.error('No se encontró el tasker');
          }
        } catch (error) {
          console.error('Error cargando el tasker:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadTasker();
  }, [taskerId]);

  const handleLogout = async () => {
    try {
      await logout();
      onClose(); // Close the modal after successful logout
    } catch (error) {
      console.error('Error logging out:', error);
      alert('Error al cerrar sesión. Por favor, intente nuevamente.');
    }
  };

  if (loading) {
    return <Modal isOpen={isOpen} onClose={onClose}><div>Cargando...</div></Modal>;
  }

  if (!tasker) {
    return <Modal isOpen={isOpen} onClose={onClose}><div>Tasker no encontrado</div></Modal>;
  }

  console.log('Rendering modal with isOpen:', isOpen);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="md"
      scrollBehavior="inside"
      className="pt-16 relative"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <Avatar
              src={tasker.photo_selfie_url || 'https://placehold.co/600x400'}
              className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-32 h-32 border-4 border-white z-50"
            />
            <ModalHeader className="flex flex-col gap-1 pt-16 text-center">
              {`${tasker.name} ${tasker.last_name}`}
            </ModalHeader>
            <ModalBody>
              <div className="space-y-3">
                <ProfileItem icon={<FaEnvelope />} label="Email" value={tasker.email} />
                <ProfileItem icon={<FaPhone />} label="Teléfono" value={tasker.phone_number || 'No proporcionado'} />
                <ProfileItem icon={<FaBriefcase />} label="Carrera" value={tasker.career} />
                <ProfileItem icon={<FaUniversity />} label="Universidad" value={tasker.university} />
                <ProfileItem
                  icon={<FaCertificate />}
                  label="Certificado de Alumno Regular"
                  value={
                    <a 
                      href={tasker.certificate_regular_alumn_url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-purple-500 hover:underline"
                    >
                      Ver certificado
                    </a>
                  }
                />
                <ProfileItem
                  icon={<FaCertificate />}
                  label="Certificado de Antecedentes Penales"
                  value={
                    <a 
                      href={tasker.certificate_criminal_record_url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-purple-500 hover:underline"
                    >
                      Ver certificado
                    </a>
                  }
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button 
                color="danger" 
                variant="light" 
                onPress={handleLogout}
                size="sm"
                className="w-full"
              >
                Cerrar Sesión
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ProfileModal;
