import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Button } from '@nextui-org/react';
import { FaTasks, FaHome, FaBars, FaCalendar, FaBriefcase } from 'react-icons/fa';
import ProfileModal from '../ProfileModal/index';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { user, logout, isApproved } = useAuth();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const menuItems = [
    ...(isApproved
      ? [
        { key: "home", icon: <FaHome />, title: "Home", href: "/" },
        { key: "tasks", icon: <FaTasks />, title: "Tasks", href: "/tasks" },
        { key: "calendar", icon: <FaCalendar />, title: "Calendario", href: "/calendar" },
      ]
      : []),
      { key: "detalles-tasker", icon: <FaBriefcase />, title: "Detalles Tasker", href: "/detalles-tasker" },
  ];

  const toggleProfileModal = () => {
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  return (
    <div className="flex w-full h-screen bg-white">
      <div className={`bg-white shadow-lg transition-all duration-300 ${isOpen ? 'w-64' : 'w-16'} flex flex-col border-r border-gray-300 relative`}>
        <Button 
          isIconOnly
          color="primary" 
          variant="light" 
          onClick={() => setIsOpen(!isOpen)}
          className={`absolute top-2 ${isOpen ? 'right-2' : 'left-2'} z-10 transition-all duration-300 text-gray-700 hover:text-purple-600 hover:bg-purple-50`}
        >
          <FaBars />
        </Button>
        <div className="flex items-center justify-between p-4 border-b border-gray-200 h-16">
          <div className={`flex items-center ${!isOpen && 'invisible'}`}>
            <Link to="/" className="text-xl font-bold text-purple-600">
              <img src="/logo-tasky.png" alt="Tasker App Logo" className="h-8 w-auto mr-2" />
            </Link>
          </div>
        </div>
        <nav className="flex-grow mt-4">
          {menuItems.map((item) => (
            <Link
              key={item.key}
              to={item.href}
              className={`flex items-center mx-2 my-1 px-2 py-2 text-gray-700 hover:bg-purple-50 hover:text-purple-600 transition-colors ${
                location.pathname === item.href ? 'bg-purple-100 text-purple-600 rounded-lg' : 'rounded-lg'
              } ${!isOpen ? 'w-10 h-10 justify-center' : 'w-auto justify-start'}`}
            >
              <span className="text-lg flex-shrink-0">{item.icon}</span>
              {isOpen && <span className="ml-3 text-sm">{item.title}</span>}
            </Link>
          ))}
        </nav>
        <div className="p-4 border-t border-gray-200">
          {user ? (
            <div 
              className={`flex ${isOpen ? 'flex-row items-center' : 'flex-col items-center'} space-y-2 cursor-pointer`}
              onClick={toggleProfileModal}
            >
              <Avatar
                src={user.email || "https://i.pravatar.cc/150?u=a042581f4e29026024d"}
                size="lg"
              />
              <div className={`ml-3 ${!isOpen && 'hidden'}`}>
                <span className="block text-sm font-medium text-gray-700">
                  {user.email?.split('@')[0]}
                </span>
              </div>
            </div>
          ) : (
            <Button
              as={Link}
              to="/login"
              color="primary"
              variant="solid"
              fullWidth
            >
              Login
            </Button>
          )}
        </div>
      </div>

      <main className="flex-1 overflow-x-hidden w-full overflow-y-auto bg-white">
        <div className="w-full mx-auto px-4 py-8">
          {children}
        </div>
      </main>
      <ProfileModal isOpen={isProfileModalOpen} onClose={() => setIsProfileModalOpen(false)} />
    </div>
  );
};

export default Layout;