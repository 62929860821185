import React from "react";
import loginHero from "../login/img/login-hero.png";

type ProfileCreationRightColumnProps = {
  step: number;
};

const ProfileCreationRightColumn: React.FC<ProfileCreationRightColumnProps> = ({
  step,
}) => {
  const stepTexts = [
    "Ingresa tus datos personales",
    "Selecciona tus categorías",
    "Sube tus documentos",
  ];

  return (
    <div className="hidden flex-grow bg-purple-500 md:flex items-center justify-center p-4 md:p-8 relative">
      <div className="w-full h-full flex flex-col justify-center items-center">
        <img
          src={loginHero}
          alt=""
          className="w-full max-w-[30rem] object-contain mb-4 md:mb-0"
        />
        <p className="italic font-bold text-lg md:text-2xl text-purple-50 uppercase w-fit pr-2 text-left md:absolute md:bottom-8 md:left-8">
          Paso {step}: {stepTexts[step - 1]}
        </p>
      </div>
    </div>
  );
};

export default ProfileCreationRightColumn;
