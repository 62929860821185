import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "../../supabase/supabase";
import { useAuth } from "../../hooks/useAuth";
import CategoryInfoModal from "../../components/CategoryInfoModal/CategoryInfoModal";
import {
  Card,
  CardBody,
  Spinner,
  Button,
  useDisclosure,
  Input,
} from "@nextui-org/react";
import {
  FaUser,
  FaEnvelope,
  FaUniversity,
  FaBriefcase,
  FaBox,
  FaCheckCircle,
  FaPhone,
  FaGraduationCap,
  FaFileAlt,
  FaInfoCircle,
  FaArrowRight,
} from "react-icons/fa";
import ProfileItem from "../../components/ProfileModal/ProfileItem";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@nextui-org/react";
import CategorySelectionStep from "../../components/TaskerProfileCreation/CategorySelectionStep";
import ProfileEditModal from "../../components/ProfileModal/ProfileEditModal";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface CategoryInfo {
  id: number;
  name: string;
  info: any;
  is_completed: boolean;
}

interface ProfileInfo {
  name: string;
  last_name: string;
  phone_number: string;
  university: string;
  career: string;
  university_year: string;
  email: string;
  certificate_regular_alumn_url: string;
  certificate_criminal_record_url: string;
  photo_selfie_url: string;
  [key: string]: string;
}

interface TaskerCategory {
  id: number;
  category_id: number;
  details: any;
  is_completed: boolean;
  categories: {
    id: number;
    name: string;
  };
}

const TaskerCategoryDetails: React.FC = () => {
  const [categories, setCategories] = useState<CategoryInfo[]>([]);
  const { user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<CategoryInfo | null>(
    null
  );
  const [modalKey, setModalKey] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tempSelectedCategories, setTempSelectedCategories] = useState<
    number[]
  >([]);
  const [loadingCategories, setLoadingCategories] = useState<{
    [key: number]: boolean;
  }>({});
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [profileInfo, setProfileInfo] = useState<ProfileInfo>({
    name: "",
    last_name: "",
    phone_number: "",
    university: "",
    career: "",
    university_year: "",
    email: "",
    certificate_regular_alumn_url: "",
    certificate_criminal_record_url: "",
    photo_selfie_url: "",
  });
  const [isApproved, setIsApproved] = useState(false);

  const fetchTaskerCategories = useCallback(async () => {
    if (!user) return;

    try {
      const { data: taskerData, error: taskerError } = await supabase
        .from("taskers")
        .select("*")
        .eq("auth_id", user.id)
        .single();

      if (taskerError) throw taskerError;
      if (!taskerData) throw new Error("Tasker not found");

      const taskerId = taskerData.id;

      setProfileInfo({
        name: taskerData.name,
        last_name: taskerData.last_name,
        phone_number: taskerData.phone_number,
        university: taskerData.university,
        career: taskerData.career,
        university_year: taskerData.university_year,
        email: taskerData.email,
        certificate_regular_alumn_url: taskerData.certificate_regular_alumn_url,
        certificate_criminal_record_url:
          taskerData.certificate_criminal_record_url,
        photo_selfie_url: taskerData.photo_selfie_url,
      });

      setIsApproved(taskerData.is_approved);

      const { data, error } = await supabase
        .from("taskers_categories")
        .select(`
          id,
          category_id,
          details,
          is_completed,
          categories (id, name)
        `)
        .eq("tasker_id", taskerId) as { data: TaskerCategory[] | null, error: any };

      if (error) throw error;

      const processedCategories = data?.map((item: TaskerCategory) => ({
        id: item.category_id,
        name: item.categories.name,
        info: item.details,
        is_completed: item.is_completed,
      }));

      setCategories(processedCategories || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching tasker categories:", error);
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchTaskerCategories();
  }, [fetchTaskerCategories]);

  const handleEditCategory = (category: CategoryInfo) => {
    setLoadingCategories((prev) => ({ ...prev, [category.id]: true }));
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  const handleSaveCategory = async (updatedInfo: any) => {
    try {
      if (!selectedCategory || !user) return;

      const { data: taskerData, error: taskerError } = await supabase
        .from("taskers")
        .select("id")
        .eq("auth_id", user.id)
        .single();

      if (taskerError) throw taskerError;
      if (!taskerData) throw new Error("Tasker not found");

      const { error } = await supabase
        .from("taskers_categories")
        .update({ details: updatedInfo })
        .eq("category_id", selectedCategory.id)
        .eq("tasker_id", taskerData.id);

      if (error) throw error;

      setCategories(
        categories.map((cat) =>
          cat.id === selectedCategory.id ? { ...cat, info: updatedInfo } : cat
        )
      );
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const updateCategoryDetails = async (categoryId: number, details: any) => {
    try {
      if (!user) {
        throw new Error("User not authenticated");
      }

      const { data: taskerData, error: taskerError } = await supabase
        .from("taskers")
        .select("id")
        .eq("auth_id", user.id)
        .single();

      if (taskerError) throw taskerError;
      if (!taskerData) throw new Error("Tasker not found");

      const taskerId = taskerData.id;

      const { data, error } = await supabase
        .from("taskers_categories")
        .update({ details: details, is_completed: true })
        .eq("id", categoryId)
        .eq("tasker_id", taskerId);

      if (error) throw error;

      setCategories((prevCategories) =>
        prevCategories.map((cat) =>
          cat.id === categoryId
            ? { ...cat, info: details, is_completed: true }
            : cat
        )
      );

      setLoadingCategories((prev) => ({ ...prev, [categoryId]: false }));
      alert("Categoría actualizada exitosamente");
    } catch (error) {
      console.error("Error updating category details:", error);
      alert("Error al actualizar la categoría. Por favor, intente nuevamente.");
    }
  };

  const handleCategoryChange = (categories: number[]) => {
    setTempSelectedCategories(categories);
  };

  const handleCategorySubmit = async (categoryIds: number[]) => {
    if (!user) return;

    try {
      setIsLoading(true);

      const { data: taskerData, error: taskerError } = await supabase
        .from("taskers")
        .select("id")
        .eq("auth_id", user.id)
        .single();

      if (taskerError) throw taskerError;
      if (!taskerData) throw new Error("Tasker not found");

      const taskerId = taskerData.id;

      // Fetch existing categories
      const { data: existingCategories, error: fetchError } = await supabase
        .from("taskers_categories")
        .select("category_id")
        .eq("tasker_id", taskerId);

      if (fetchError) throw fetchError;

      const existingCategoryIds = existingCategories.map(cat => cat.category_id);

      // Determine categories to add
      const categoriesToAdd = categoryIds.filter(id => !existingCategoryIds.includes(id));

      // Add new categories
      if (categoriesToAdd.length > 0) {
        const categoriesToInsert = categoriesToAdd.map(categoryId => ({
          tasker_id: taskerId,
          category_id: categoryId,
        }));

        const { error: insertError } = await supabase
          .from("taskers_categories")
          .insert(categoriesToInsert);

        if (insertError) throw insertError;
      }

      // Refresh the categories list
      await fetchTaskerCategories();
      onClose();
      toast.success("Categorías actualizadas exitosamente", {
        position: "bottom-center",
        className: "bg-green-600 text-white",
      });
    } catch (error) {
      console.error("Error submitting categories:", error);
      toast.error("Error al actualizar las categorías. Por favor, intente nuevamente.", {
        position: "bottom-center",
        className: "bg-red-600 text-white",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfileUpdate = async (updatedInfo: any) => {
    try {
      if (!user) throw new Error("User not authenticated");

      // Update taskers table
      const { error: taskerError } = await supabase
        .from("taskers")
        .update(updatedInfo)
        .eq("auth_id", user.id);

      if (taskerError) throw taskerError;

      // Update auth.users table
      const { error: authError } = await supabase.auth.updateUser({
        data: updatedInfo,
      });

      if (authError) throw authError;

      setProfileInfo((prevInfo) => ({ ...prevInfo, ...updatedInfo }));
      alert("Perfil actualizado exitosamente");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Error al actualizar el perfil. Por favor, intente nuevamente.");
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = e.target.files?.[0];
    if (!file || !user) return;

    try {
      // Show loading toast
      const loadingToastId = toast.loading("Subiendo archivo...", {
        position: "bottom-center",
        className: "bg-purple-600 text-white",
      });

      // Create a FormData object
      const formData = new FormData();
      formData.append('file', file);
      formData.append('folder', fileType);

      // Call the edge function using Supabase client
      const { data, error } = await supabase.functions.invoke('uploadTaskerFiles', {
        body: formData,
      });

      if (error) throw error;
      if (!data || !data.url) throw new Error('No URL returned from file upload');

      const fileUrl = data.url;

      // Update the database with the new file URL
      const { error: updateError } = await supabase
        .from("taskers")
        .update({
          [`${fileType}_url`]: fileUrl,
        })
        .eq("auth_id", user.id);

      if (updateError) throw updateError;

      setProfileInfo((prev) => ({
        ...prev,
        [`${fileType}_url`]: fileUrl,
      } as ProfileInfo));

      // Dismiss loading toast and show success toast
      toast.dismiss(loadingToastId);
      toast.success(`${fileType} actualizado exitosamente`, {
        position: "bottom-center",
        className: "bg-green-600 text-white",
      });
    } catch (error) {
      console.error(`Error updating ${fileType}:`, error);
      toast.error(`Error al actualizar ${fileType}. Por favor, intente nuevamente.`, {
        position: "bottom-center",
        className: "bg-red-600 text-white",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="lg" color="primary" />
      </div>
    );
  }

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <h1 className="text-4xl font-bold mb-8 text-center text-purple-600">
        {isApproved ? "Mi Perfil y Categorías" : "Perfil en Revisión"}
      </h1>
      {!isApproved && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-8" role="alert">
          <p className="font-bold">Perfil en revisión</p>
          <p>Tu perfil está siendo revisado por nuestro equipo. Mientras tanto, Debes subir los documentos necesarios y completar tus categorías.</p>
        </div>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left column: Profile information */}
        <Card className="shadow-lg bg-white">
          <CardBody>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold text-purple-600">
                Información del Perfil
              </h2>
              {isApproved && (
                <Button
                  onClick={() => setIsProfileModalOpen(true)}
                  className="bg-purple-500 hover:bg-purple-600 text-white"
                >
                  Editar Perfil
                </Button>
              )}
            </div>
            <div className="space-y-4">
              <ProfileItem
                icon={<FaUser className="text-purple-500" />}
                label="Nombre"
                value={`${profileInfo.name} ${profileInfo.last_name}`}
              />
              <ProfileItem
                icon={<FaEnvelope className="text-purple-500" />}
                label="Email"
                value={profileInfo.email}
              />
              <ProfileItem
                icon={<FaPhone className="text-purple-500" />}
                label="Teléfono"
                value={profileInfo.phone_number}
              />
              <ProfileItem
                icon={<FaUniversity className="text-purple-500" />}
                label="Universidad"
                value={profileInfo.university}
              />
              <ProfileItem
                icon={<FaBriefcase className="text-purple-500" />}
                label="Carrera"
                value={profileInfo.career}
              />
              <ProfileItem
                icon={<FaGraduationCap className="text-purple-500" />}
                label="Año Universitario"
                value={profileInfo.university_year}
              />
              <ProfileItem
                icon={<FaFileAlt className="text-purple-500" />}
                label="Certificado de Alumno Regular"
                value={
                  <a
                    href={profileInfo.certificate_regular_alumn_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Ver certificado
                  </a>
                }
              />
              <ProfileItem
                icon={<FaFileAlt className="text-purple-500" />}
                label="Certificado de Antecedentes Penales"
                value={
                  <a
                    href={profileInfo.certificate_criminal_record_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Ver certificado
                  </a>
                }
              />
            </div>
          </CardBody>
        </Card>

        {/* Right column: Categories list */}
        <div>
          <h2 className="text-2xl font-semibold mb-4 text-purple-600">
            Categorías y Habilidades
          </h2>
          {categories.length === 0 ? (
            <div className="text-center">
              <FaBox className="text-6xl text-gray-300 mb-4 mx-auto" />
              <p className="text-xl text-gray-600">
                No tienes categorías seleccionadas
              </p>
              <Button
                onClick={onOpen}
                className="mt-4 bg-purple-500 hover:bg-purple-600 text-white"
              >
                Agregar Categorías
              </Button>
            </div>
          ) : (
            <div>
              <div className="space-y-4">
                {categories.map((category) => (
                  <div
                    key={category.id}
                    className="flex items-center justify-between p-4 bg-white rounded-lg shadow"
                  >
                    <span className="text-lg font-semibold">
                      {category.name}
                    </span>
                    <div className="flex items-center space-x-2">
                      {category.is_completed ? (
                        <span className="text-green-500 flex items-center">
                          <FaCheckCircle className="mr-1" /> Listo!
                        </span>
                      ) : (
                        <>
                          <span className="text-yellow-500 flex items-center">
                            <FaInfoCircle className="mr-1" />
                            Te falta información
                          </span>
                          <Button
                            size="sm"
                            isIconOnly
                            color="primary"
                            onClick={() => handleEditCategory(category)}
                            className="p-1"
                          >
                            <FaArrowRight />
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {categories.length < 5 && (
                <Button
                  onClick={onOpen}
                  className="mt-4 bg-purple-500 hover:bg-purple-600 text-white"
                >
                  Agregar más Categorías
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      {/* File Upload Section */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4 text-purple-600">
          Documentos
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {[
            { type: "certificate_regular_alumn", label: "Certificado de Alumno Regular" },
            { type: "certificate_criminal_record", label: "Certificado de Antecedentes" },
            { type: "photo_selfie", label: "Foto de Perfil" },
          ].map((doc) => (
            <div key={doc.type} className="bg-white p-4 rounded-lg shadow">
              <label htmlFor={doc.type} className="block text-sm font-medium text-gray-700 mb-2">
                {doc.label}
              </label>
              <Input
                type="file"
                id={doc.type}
                onChange={(e) => handleFileUpload(e, doc.type)}
                accept={doc.type === "photo_selfie" ? "image/*" : ".pdf,.jpg,.jpeg,.png"}
                className="w-full"
              />
              {profileInfo[`${doc.type}_url`] && (
                <a
                  href={profileInfo[`${doc.type}_url`]}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-2 inline-block text-sm text-blue-600 hover:underline"
                >
                  Ver documento actual
                </a>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Keep the existing modals */}
      {selectedCategory && (
        <CategoryInfoModal
          key={modalKey}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={(info: any) =>
            updateCategoryDetails(selectedCategory.id, info)
          }
          category={selectedCategory}
        />
      )}
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        size="3xl"
        aria-labelledby="category-selection-modal"
        scrollBehavior="inside"
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">
            <h2 id="category-selection-modal">Selecciona tus categorías</h2>
          </ModalHeader>
          <ModalBody className="overflow-y-auto max-h-[70vh]">
            <CategorySelectionStep
              onCategoryChange={handleCategoryChange}
              onSubmit={handleCategorySubmit}
              isLoading={isLoading}
              selectedCategories={tempSelectedCategories}
              onPrev={onClose}
              setSelectedCategories={setTempSelectedCategories}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <ProfileEditModal
        isOpen={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
        onSave={handleProfileUpdate}
        profileInfo={profileInfo}
      />
    </div>
  );
};

export default TaskerCategoryDetails;

