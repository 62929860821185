import React from 'react';
import loginHero from '../login/img/login-hero.png';

const RegisterRightColumn: React.FC = () => {
  return (
    <div className="flex-grow bg-purple-500 flex items-center justify-center p-4 md:p-8 relative">
      <div className="w-full h-full flex flex-col justify-center items-center">
        <img
          src={loginHero}
          alt=""
          className="w-full max-w-[30rem] object-contain mb-4 md:mb-0"
        />
        <p className="italic font-bold text-lg md:text-2xl text-purple-50 uppercase w-full text-left md:absolute md:bottom-8 md:left-8">
          Únete a Tasky. <br /> Resuelve tasks y gana dinero.
        </p>
      </div>
    </div>
  );
};

export default RegisterRightColumn;
